import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, Renderer2, ViewChild, inject } from '@angular/core';
import { addMilliseconds, differenceInMilliseconds, differenceInMinutes, subDays, subMilliseconds } from 'date-fns';
import { EChartsType } from 'echarts';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, debounceTime, interval, map, share, startWith, tap, withLatestFrom } from 'rxjs';
import { DateRange } from 'src/app/core/plots/plot.models';
import { detailsRepository } from 'src/app/core/stores/details.repository';
import * as echarts from 'echarts';
import { ChargingSession, Connector, OverallStatus } from 'src/app/core/data-backend/models';
import { StateColors, StateHelperService } from 'src/app/core/helpers/state-helper.service';
import { TimelineHelperService } from 'src/app/core/plots/timeline-helper.service';
import { NotificationService } from 'src/app/core/app-services';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'plot-range-slider',
    template: `
            <div class="w-100 range-slider-wrapper" 
                #chartWrapper
                (mousewheel)="zoomOnMousewheel($event)"
            >
                <div class="range-slider-container"></div>
                <div class="box-rounded p-0 chart-background"></div>

                <div class="cover-left" #coverLeft></div>

                <div class="slider-element"
                    #zoomWindow
                >
                    <span 
                        class="resize-handler handler-left"
                        (mousedown)="setListeners($event)"
                    ></span>
                    <div class="border-filler-left"></div>
                    <div class="border-filler-right move-handler"
                        (mousedown)="setListeners($event)"
                    ></div>
                    <span 
                        class="resize-handler handler-right"
                        (mousedown)="setListeners($event)"
                    ></span>
                </div>

                <div class="cover-right" #coverRight></div>
            </div>

            <div class="flex-row align-items-center justify-content-between mt-16 pb-32">
                <button 
                    class="chevron-timeline-button chevron-left" 
                    (click)="extendRange$.next('older')"
                >
                    {{ 'DETAILS_VIEW.LOAD_OLDER' | translate }}
                </button>
                <button 
                    class="focus-button"
                    (click)="focusOnSelection()"
                    [disabled]="inputtedZoomRange[0] === 0 && inputtedZoomRange[1] === 100"
                >{{ 'DETAILS_VIEW.FOUCS_ON_SELECTION' | translate }}</button>
                <button 
                    class="chevron-timeline-button chevron-right" 
                    (click)="extendRange$.next('newer')"
                    [disabled]="!(loadNewerActive$ | async)"
                >
                    {{ 'DETAILS_VIEW.LOAD_NEWER' | translate }}
                </button>
            </div>
    `,
    styleUrls: ['./plot-range-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlotRangeSliderComponent implements AfterViewInit, OnDestroy {
    private _timelineHelper: TimelineHelperService = inject(TimelineHelperService);
    private _permService: PermissionsService = inject(PermissionsService);
    // zoom window Ref
    @ViewChild('zoomWindow') zoomWindowRef: ElementRef | undefined;
    // Ref of zoom-bar (static plot wrapper)
    @ViewChild('chartWrapper') chartWrapper: ElementRef | undefined;
    // cover elements on both sides of zoom window
    @ViewChild('coverLeft') coverLeft: ElementRef | undefined;
    @ViewChild('coverRight') coverRight: ElementRef | undefined;
    // settings for component
    public settings: {minWidth: number} = {minWidth: 2};
    // manage event listeners
    private _eventListeners: any[] = [];
    private _stateHelper: StateHelperService = new StateHelperService();
    private _stateColors: StateColors = this._stateHelper.getStateColors();
    // Events of load older/newer btns
    public extendRange$: Subject<'older' | 'newer' | null> = new BehaviorSubject<'older' | 'newer' | null>(null);
    // controls disabled state of "load newer"
    public loadNewerActive$: Observable<boolean>;
    // static chart below connected range slider
    public staticChart: EChartsType | undefined;
    // store session data, use setter to update with new data
    private _sessionData: ChargingSession[] = [];
    @Input() set sessionData(data: ChargingSession[] | null) {
        this._sessionData = data || [];
        this._updateRangeSlider();
    }
    // get selected connectors to plot lane for each in selection
    private _selectedConnectors: Connector[] = [];
    @Input() set selectedConnectors(data: Connector[] | null) {
        this._selectedConnectors = data || [];
        this._updateRangeSlider();
    }
    private _dateRange: DateRange = {
        from: subDays(new Date(), 14),
        until: new Date()
    };
    // helper to control if and when to translate on new dateRange input
    // currently initially false to wait for first "real" zoomRange
    private _markForTranslation: boolean = false;
    @Input() set dateRange(data: DateRange | null) {
        if (data === null) return;
        if (this._markForTranslation) {
            this._translateZoomRange(data)
        } else {
            this._dateRange = data
            this._updateRangeSlider()
        }
    }
    private _overallStates: OverallStatus[] = [];
    @Input() set overallStates(data: OverallStatus[] | null) {
        if (data === null) return
        this._overallStates = data;
        this._updatePlotSeries()
    }
    private _featuredState: ('chargingModelState' | 'errorState' | 'heartbeatState' | 'overallState' | 'healthIndex') = 'overallState';
    @Input() set featuredState(state: typeof this._featuredState) {
        this._featuredState = state;
        this._updatePlotSeries()
    }
    public inputtedZoomRange: [number, number] = [90, 100];
    @Input() set zoomRange(data: [number, number] | null) {
        if (data == null || data[0] === this.inputtedZoomRange[0] && data[1] === this.inputtedZoomRange[1]) return
        // allow translation after first zoomRange input
        this._markForTranslation = true
        this.inputtedZoomRange = data
        this._setWindow(data[0], 100 - data[1], true)
    };
    // show hiding state, do not show spinner or text
    @Input() set loading(data: boolean | null) {
        if (!this.staticChart) return
        if (data === true || data === null) {
            this.staticChart.showLoading('default', {
                text: '',
                showSpinner: false
            })
        } else {
            this.staticChart.hideLoading()
        }
    }
    // whether the background lines should be displayed
    private _showBgLines: boolean = true;
    @Input('showBgLines') set showBgLines(data: boolean | null) {
        if (data == null) this._showBgLines = true;
        else this._showBgLines = data;
    }
    @Output() zoomRangeChange = new EventEmitter<[number, number]>();
    // observe changes in sizing of window or parent
    private _resizeObserver: ResizeObserver | undefined;
    // keep track of changes in host width
    private _chartWrapperDimensions$ = new BehaviorSubject<DOMRect | null>(null);

    constructor(
        private _elRef: ElementRef,
        private _renderer: Renderer2,
        private _ngZone: NgZone,
        public detailsRepo: detailsRepository,
        public notificationService: NotificationService
    ) {
        // react to extend range button events, set updated dateRange
        this.extendRange$.pipe(
            takeUntilDestroyed(),
            withLatestFrom(this.detailsRepo.dateRange$),
            tap(([action, dateRange]) => {
                // get time in current interval, get 50% of it to pre- or append to dateRange
                const updateRangeInMs = (dateRange.until.getTime() - dateRange.from.getTime()) / 2;
                // check if "newer" would be in the future, cap newer data to current datetime
                const msUntilNow = differenceInMilliseconds(new Date(), dateRange.until);
                const updateUntilInMs = updateRangeInMs > msUntilNow ? msUntilNow : updateRangeInMs;
                // update bounds based on selected action
                const newRange: DateRange = {
                    from:   action == 'older' ? subMilliseconds(dateRange.from, updateRangeInMs) : dateRange.from,
                    until:  action == 'newer' ? addMilliseconds(dateRange.until, updateUntilInMs) : dateRange.until,
                };
                this.detailsRepo.setDateRange(newRange)
            })
        ).subscribe()

        // check every minute or with new range if newer data can be fetched
        this.loadNewerActive$ = combineLatest({
            dateRange: this.detailsRepo.dateRange$,
            timer: interval(60000).pipe(startWith(0))
            // currently emits once a minute from init time
            // could be synced with a custom scheduler, to only emit on full minutes, if necessary
        }).pipe(
            takeUntilDestroyed(),
            map(({ dateRange }) => differenceInMinutes(new Date(), dateRange.until) > 14),
            share({connector: () => new ReplaySubject(1)})
        );

        // throttle resize events and update static chart
        this._chartWrapperDimensions$.pipe(
            takeUntilDestroyed(),
            debounceTime(50),
            tap((rect) => {
                if (!this.staticChart || !rect) return
                this.staticChart.resize({
                    width: rect.width,
                    height: rect.height,
                    // @ts-ignore
                    animation: {
                        duration: 150
                    }
                })
            })
        ).subscribe();
    }

    ngAfterViewInit(): void {
        this._initRangeSlider()
        this._setWindow(this.inputtedZoomRange[0], 100 - this.inputtedZoomRange[1])

        // apply additional styling to plot element, which gets stripped after init
        if (!this.chartWrapper) return
        const el = this.chartWrapper.nativeElement.querySelector('.range-slider-container > div');
        el.style.borderRadius = '8px 8px 0 0';

        // observe changes in wrapper sizing
        if (this.chartWrapper) {
            // provide width of wrapper to hostWidth$
            this._resizeObserver = new ResizeObserver((entries) => {
                // ResizeObserver runs outside of Angular Zone
                this._ngZone.run(() => {
                    // we cannot use the provided Rect of the resizeObserver, as x and y in DOM are not provided properly
                    // thus we need to manually get the boundingClientRect
                    this._chartWrapperDimensions$.next(this.chartWrapper!.nativeElement.getBoundingClientRect())
                })
            });
            this._resizeObserver.observe(this.chartWrapper.nativeElement);
        }
    }

    // applies current zoomRange to new dateRange, resizing zoom window to keep relative position
    private _translateZoomRange(newDateRange: DateRange) {
        // current interval of whole plot
        const currentInterval = this._dateRange.until.getTime() - this._dateRange.from.getTime();
        // calc times (in ms) on which window handles are currently sitting
        const currentStartTime  = this.inputtedZoomRange[0] / 100 * currentInterval + this._dateRange.from.getTime();
        const currentEndTime    = this.inputtedZoomRange[1] / 100 * currentInterval + this._dateRange.from.getTime();
        
        // get percent value of positioning on new dateRange
        const newInterval = newDateRange.until.getTime() - newDateRange.from.getTime();
        let leftHandlePercent = newDateRange.from.getTime() == this._dateRange.from.getTime() ? (currentStartTime - newDateRange.from.getTime()) / newInterval * 100 : 0,
            rightHandlePercent = newDateRange.until.getTime() == this._dateRange.until.getTime() ? (currentEndTime - newDateRange.from.getTime()) / newInterval * 100 : 100;

        // cap handles at min / max positions for minWidth in settings
        leftHandlePercent   = leftHandlePercent > 100 ? 100 - this.settings.minWidth : leftHandlePercent < 0 ? 0 : leftHandlePercent;
        rightHandlePercent  = rightHandlePercent > 100 ? 100 : rightHandlePercent < 0 ? 0 + this.settings.minWidth : rightHandlePercent;

        // finally set new date range in component
        this._dateRange = newDateRange;
        // set and emit new zoom range
        this.zoomRangeChange.emit([leftHandlePercent, rightHandlePercent]);
        this.inputtedZoomRange = [leftHandlePercent, rightHandlePercent];
        // set styling to zoom window
        this._setWindow(leftHandlePercent, 100 - rightHandlePercent, true);
        this._updateRangeSlider()
    }

    // calculates currently viewed dateRange
    // updates global dateRange, sets dataZoom to view full range
    public focusOnSelection() {
        // get total time in current dateRange
        const timeInInterval = this._dateRange.until.getTime() - this._dateRange.from.getTime();
        // calc start time based on zoom-percent
        const startTime = this.inputtedZoomRange[0] / 100 * timeInInterval;
        // add to start time, get left bounding date
        const newStart = new Date(this._dateRange.from.getTime() + startTime);
        // calc end time based on zoom-percent
        const endTime = this.inputtedZoomRange[1] / 100 * timeInInterval;
        // add calculated time to current from date, get right bounding date
        const newEnd = new Date(this._dateRange.from.getTime() + endTime);

        // store new global dateRange in repo
        this.detailsRepo.setDateRange({
            from: newStart,
            until: newEnd
        });

        // update current zoom to cover whole selection
        // let the next input handle the update of the window
        this.zoomRangeChange.emit([0, 100]);
    }

    private _initRangeSlider() {
        if (this.staticChart || !this._elRef || !this._elRef.nativeElement) return
        this.staticChart = echarts.init(this._elRef.nativeElement.querySelector('.range-slider-container'), undefined, {
            renderer: 'svg'
        });
        this._updateRangeSlider()
    }

    private _updateRangeSlider() {
        if (!this.staticChart) {
            this._initRangeSlider();
            return
        }

        this._updateConnectors()
        this._updateData()

        // @ts-ignore
        this.staticChart.setOption({
            tooltip: {
                trigger: 'none'
            },
            grid: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 21
            },
            xAxis: {
                type: 'time',
                show: true,
                min: +this._dateRange.from,
                max: +this._dateRange.until,
                axisLabel: {
                    show: true,
                    inside: true,
                    margin: -20,
                    padding: [0, 0, 0, 6],
                    align: 'left',
                    color: '#C4C4C4',
                    showMinLabel: true,
                    showMaxLabel: true,
                    formatter: {
                        // keep format mostly the same, as this axis will never be zoomed into
                        // @ts-ignore
                        year: '{dd}.{MM}.{yy}',
                        month: '{dd}.{MM}.{yy}',
                        day: '{dd}.{MM}.{yy}',
                        hour: '{dd}.{MM}.{yy}',
                        minute: '{dd}.{MM}.{yy} {HH}:{mm}',
                        second: '{dd}.{MM}.{yy} {HH}:{mm}',
                        millisecond: '{dd}.{MM}.{yy} {hh}:{mm}',
                        none: '{dd}.{MM}.{yy} {hh}:{mm}'
                    }
                },
                axisTick: {
                    show: true,
                    length: 35,
                    lineStyle: {
                        color: '#C4C4C4'
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#C4C4C4',
                        width: 1
                    }
                },
                axisLine: {
                    show: false
                }
            },
            yAxis: {
                id: 'connector-lanes',
                type: 'category',
                show: true,
                inverse: true,
                splitLine: {
                    show: true
                },
                axisLine: {
                    show: false
                }
            },
            series: []
        })
    }

    private _updateConnectors() {
        if (!this.staticChart) {
            this._initRangeSlider();
            return;
        }

        const options = this.staticChart.getOption()?.['yAxis'];
        if (!options) return;

        this.staticChart.setOption({
            yAxis: {
                data: this._selectedConnectors.map(con => 'con-' + con.connectorId)
            }
        })   
    }

    private async _updatePlotSeries() {
        if (!this.staticChart) {
            this._initRangeSlider()
            return;
        }

        this._updateConnectors()

        // make sure permissions are loaded
        await this._permService.awaitPermissions();

        const blockColor = this._stateHelper.getVar('--timeline-session-blocks');

        let backgroundLineData: any[] = [];

        let conDataSets: any[]  = [],
            conSeries: any[]    = [
                {
                    name: 'sessions-in-slider',
                    type: 'custom',
                    encode: {
                        x: ['startDate', 'stopDate'],
                        y: 'connectorId'
                    },
                    datasetIndex: 0,
                    renderItem: (params: any, api: any) => {
                        const sessionMinWidth = 2;
                        let connectorId = api.value('connectorId'),
                            height      = params.coordSys.height / this._selectedConnectors.length,
                            startPoint  = api.coord([new Date(api.value('startDate')), connectorId]),
                            endPoint    = api.coord([new Date(api.value('stopDate')), height]),
                            type        = api.value('type'),
                            typeColor   = ['regular', 'in_progress'].includes(type) ? blockColor : this._stateColors.toBeMonitored,
                            width       = endPoint[0] - startPoint[0],
                            sessionRect = echarts.graphic.clipRectByRect(
                                {
                                    x: startPoint[0],
                                    y: startPoint[1] - height / 2,
                                    width: width > sessionMinWidth ? width : sessionMinWidth,
                                    height: height
                                },
                                {
                                    x: params.coordSys.x,
                                    y: params.coordSys.y,
                                    width: params.coordSys.width,
                                    height: params.coordSys.height
                                }
                            );

                        return sessionRect && {
                            type: 'rect',
                            transition: ['shape'],
                            shape: sessionRect,
                            style: {
                                fill: typeColor
                            }
                        }
                    }
                },
                {
                    name: 'overall-states-background',
                    type: 'line',
                    showSymbol: false,
                    encode: {
                        x: 1,
                        y: 0
                    },
                    data: backgroundLineData,
                    lineStyle: {
                        color: '#C4C4C4',
                        width: 6,
                        cap: 'round'
                    }
                }
            ],
            conVisMaps: any[]   = [];

        // modelStates of type OverallStatus mapped to global stateModel permissions
        const stateMap = {
            overallState: 'lastOverallState',
            heartbeatState: 'lastHeartbeatState',
            healthIndex: 'lastHealthIndexValue',
            errorState: 'lastErrorState',
            chargingModelState: 'lastChargingModelState'
        } as const;

        const permissionState = stateMap[this._featuredState];

        if (this._permService.hasStateModel(permissionState)) {
            this._selectedConnectors.forEach((connector, index) => {

                if (this._showBgLines) {
                    backgroundLineData.push(
                        ['con-'+connector.connectorId, this._dateRange.from.toISOString()],
                        ['con-'+connector.connectorId, this._dateRange.until.toISOString()],
                        null
                    )
                }

                conDataSets.push({
                    id: 'data-for-'+connector.connectorId,
                    dimensions: ['connectorId', 'timestamp', 'evaluation'],
                    source: [
                        ['connectorId', 'timestamp', 'evaluation'],
                        ...this._overallStates
                            .filter((overallState) => overallState.connectorId === connector.connectorId)
                            .map((overallState) => 
                                ['con-' + overallState.connectorId, overallState.date, overallState[this._featuredState]]
                            )
                    ]
                });

                conSeries.push({
                    id: 'state-series-for-'+connector.connectorId,
                    type: 'line',
                    showSymbol: false,
                    datasetIndex: index + 1, // first dataSet is always of sessions
                    z: 10,
                    encode: {
                        x: 1,
                        y: 0
                    },
                    lineStyle: {
                        width: 6,
                        cap: 'round'
                    }
                });

                
                // only apply visMap if overall state is not restricted
                conVisMaps.push({
                    id: 'state-map-for-'+connector.connectorId,
                    type: 'piecewise',
                    show: false,
                    seriesIndex: index + 2, // first two series are sessions and overall state background lines
                    dimension: 'timestamp',
                    pieces: this._timelineHelper.visualMapper(conDataSets[index].source, 2, 1, 'evaluations')
                })

            })
        }

        this.staticChart.setOption({
            dataset: conDataSets,
            series: conSeries,
            visualMap: conVisMaps
        })
    }

    private _updateData() {
        if (!this.staticChart) {
            this._initRangeSlider()
            return;
        }

        const selectedConnectorIds = this._selectedConnectors.map((con) => con.connectorId);

        let dataSets = [
            {
                id: 'sessions',
                dimensions: ['connectorId', 'startDate', 'stopDate', 'type'],
                source: [
                    ['connectorId', 'startDate', 'stopDate', 'type'],
                    ...this._sessionData
                        .filter((session) => selectedConnectorIds.indexOf(session.connectorId) > -1)
                        .map((session) => {
                            // some session do not contain a stop date, eg in_progress
                            let stopDate: string | undefined;
                            if (session.stopDate) {
                                stopDate = session.stopDate
                            } else {
                                // get all dates from meterValues
                                const allDates = session.meterValues.flatMap((meterValue) => meterValue.values.map((value) => value.date));
                                // sort and set latest date as stopDate
                                allDates.sort((a: string, b: string) => 
                                    new Date(b).getTime() - new Date(a).getTime()
                                );
                                stopDate = allDates[0]
                            }

                            return ['con-' + session.connectorId, session.startDate, stopDate, session.type]
                        })
                ]
            }
        ];

        this.staticChart.setOption({
            dataset: dataSets
        })
    }

    public setListeners(event: MouseEvent) {
        // get source of mouseDown event which triggers following eventListeners
        const target = event.target as HTMLElement;
        const action: 'resizeToLeft' | 'resizeToRight' | 'move' = target.classList.contains('move-handler')
            ? 'move'
            : target.classList.contains('handler-left')
                ? 'resizeToLeft'
                : 'resizeToRight';
        const chartWrapperDimensions = this._chartWrapperDimensions$.getValue();
        if (!chartWrapperDimensions) return

        // compute a fixed distance and mousePosition once mousedown on zoom window
        // needed for smooth moving of zoom window
        let zoomWindowRect: DOMRect = this.zoomWindowRef!.nativeElement.getBoundingClientRect(),
            distance: number,
            mousePos: number;

        if (action === 'move') {
            // get current distance between bounds
            distance = zoomWindowRect.width / chartWrapperDimensions.width * 100;
            // get current mouse pos on window to prevent position from jumping on first mousedown
            mousePos = (event.x - zoomWindowRect.x) / zoomWindowRect.width * distance;
        }

        this._eventListeners.push(
            this._renderer.listen('window', 'mousemove', (event: MouseEvent) => {
                if (action == 'move') {
                    this._moveZoomWindow(event, mousePos, distance)
                } else {
                    this._resizeZoomWindow(event, action, zoomWindowRect)
                }
            }),
            // remove listeners on mouseup, anywhere in current window
            this._renderer.listen('window', 'mouseup', (event: MouseEvent) => {
                this.removeListeners()
            })
        )
    }

    // unlisten to mouse events
    public removeListeners() {
        this._eventListeners.forEach((unlistener) => unlistener())
    }

    // handle zooming on scroll
    public zoomOnMousewheel(event: Event) {
        event.preventDefault();
    
        const wheelEvent = event as WheelEvent;
        // Trackpad events typically have smaller delta values than mouse wheel events and usually have a deltaMode of 0
        const isTrackpad = wheelEvent.deltaMode === 0 && Math.abs(wheelEvent.deltaY) < 10;
        // trackpad zoom events will have a deltaY of ~ -.9 to -0.1 and 0.1 to 0.9, but never 0
        const isTrackpadZoom = isTrackpad && wheelEvent.deltaY !== 0 && wheelEvent.deltaY > -1 && wheelEvent.deltaY < 1;
        const step = 1.5;
        let delta = 0;
        let [start, end] = this.inputtedZoomRange;
    
        // determine if vertical or horizontal scrolling
        if (wheelEvent.shiftKey || (isTrackpad && !isTrackpadZoom)) {
            delta = wheelEvent.deltaX;
            if (delta == 0) return;

            if (isTrackpad) {
                // invert direction for trackpad side-to-side movement
                delta = delta * -1
            }

            let direction = delta > 0 ? 'right' : 'left';

            // range stays the same but moves left / right
            start = start + (direction === 'right' ? step : -step);
            end = end + (direction === 'right' ? step : -step);
        } else {
            delta = wheelEvent.deltaY;
            if (delta == 0) return;

            let direction = delta > 0 ? 'down' : 'up';

            // center of range stays the same but range gets larger / smaller
            start = start + (direction === 'up' ? step : -step);
            end = end + (direction === 'up' ? -step : step);
        };

        // cap to range boundaries
        start = Math.max(0, start);
        end = Math.min(100, end);

        // peep min width in boundaries
        start = end === 100 && start > 100 - this.settings.minWidth ? 100 - this.settings.minWidth : start;
        end = start === 0 && end < 0 + this.settings.minWidth ? 0 + this.settings.minWidth : end;

        // stop on min width
        if (start + this.settings.minWidth > end) return;

        this.zoomRangeChange.emit([start, end]);
        this.inputtedZoomRange = [start, end];
        this._setWindow(start, 100 - end);
    }

    // takes MouseEvent, returns % of left offset in slider parent
    private _getOffsetLeft(event: MouseEvent): number {
        const chartWrapperDimensions = this._chartWrapperDimensions$.getValue();
        if (!chartWrapperDimensions) return 0
        let left = (event.x - chartWrapperDimensions.x) / chartWrapperDimensions.width * 100;
        left = left > 100 ? 100 : left;
        left = left < 0 ? 0 : left;

        return left
    }

    // handles resizing of zoom window (left/right handles)
    private _resizeZoomWindow(event: MouseEvent, action: 'resizeToLeft' | 'resizeToRight', zoomWindowRect: DOMRect) {
        let left = this._getOffsetLeft(event);
        const chartWrapperDimensions = this._chartWrapperDimensions$.getValue();
        if (!chartWrapperDimensions) return

        if (action === 'resizeToLeft') {
            // limit to min width
            const currentRight = (zoomWindowRect.right - chartWrapperDimensions.x) / chartWrapperDimensions.width * 100;
            left = left > currentRight - this.settings.minWidth ? currentRight - this.settings.minWidth : left;

            // emit new start and calculated current end
            this.zoomRangeChange.emit([left, currentRight])
            this.inputtedZoomRange = [left, currentRight]
            // set styling to zoom window
            this._setWindow(left, null)
        } else {
            // limit to min width
            const currentLeft = (zoomWindowRect.x - chartWrapperDimensions.x) / chartWrapperDimensions.width * 100;
            let right = 100 - left;
            right = right > 100 - currentLeft - this.settings.minWidth ? 100 - currentLeft - this.settings.minWidth : right;

            // emit calculated current start and new end
            this.zoomRangeChange.emit([currentLeft, 100 - right])
            this.inputtedZoomRange = [currentLeft, 100 - right]
            // set styling to zoom window
            this._setWindow(null, right)
        }
    }

    // handles moving of zoom window
    // requires fixed initial distance between boundaries and pointer position on window
    private _moveZoomWindow(event: MouseEvent, mousePos: number, distance: number) {
        const left = this._getOffsetLeft(event);

        // cap left / right to wrapper boundaries
        let realLeft = left - mousePos;
        realLeft = realLeft < 0 ? 0 : realLeft;

        let realRight = 100 - left - distance + mousePos;
        realRight = realRight < 0 ? 0 : realRight;

        // keep min width when hitting boundaries (which will decrease zoom distance)
        realLeft = realRight == 0 && realLeft > 100 - this.settings.minWidth ? 100 - this.settings.minWidth : realLeft;
        realRight = realLeft == 0 && realRight > 100 - this.settings.minWidth ? 100 - this.settings.minWidth : realRight;

        // emit new ranges
        this.zoomRangeChange.emit([realLeft, 100 - realRight])
        this.inputtedZoomRange = [realLeft, 100 - realRight]

        this._setWindow(realLeft, realRight)
    }

    // sets position in DOM
    private _setWindow(left: number | null, right: number | null, animate: boolean = false) {
        if (!this.zoomWindowRef || !this.coverLeft || !this.coverRight) return;
        
        if (animate) {
            this.zoomWindowRef.nativeElement.classList.add('animating');
            this.coverLeft.nativeElement.classList.add('animating');
            this.coverRight.nativeElement.classList.add('animating');

            setTimeout(() => {
                this.zoomWindowRef?.nativeElement.classList.remove('animating')       
                this.coverLeft?.nativeElement.classList.remove('animating');
                this.coverRight?.nativeElement.classList.remove('animating');
            }, 425);
        }
        
        if (left !== null) {
            this.zoomWindowRef.nativeElement.style.left = left + '%';
            this.coverLeft.nativeElement.style.width = left + '%';
        }
        if (right !== null) {
            this.zoomWindowRef.nativeElement.style.right = right + '%';
            this.coverRight.nativeElement.style.width = right + '%';
        }
    }

    ngOnDestroy(): void {
        this._resizeObserver?.unobserve(this.chartWrapper?.nativeElement);
        this.staticChart?.dispose()
    }
}
