import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, viewChild } from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    scan,
    shareReplay,
    startWith,
    Subject,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from "rxjs";
import { TableColumnHeader } from "../table-header/table-header.component";
import { isWithinInterval } from "date-fns/esm";
import { Sort } from "src/app/core/helpers/sort";
import { tablesRepository } from "src/app/core/stores/tables.repository";
import { formatDate } from "@angular/common";
import { animate, style, transition, trigger } from "@angular/animations";
import { StateHelperService } from "src/app/core/helpers/state-helper.service";
import { Filter } from "src/app/core/stores/station-filters.repository";
import { ResizedEvent } from "src/app/core/directives/resized.directive";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateService } from "@ngx-translate/core";
import { BulkSelectAction } from "../table-bulk-panel/table-bulk-panel.component";

export type TooltipConfig = {
    key?: string;
    text?: string;
    // TODO: Update to proper configurable type, get rid of max-{n} stuff
    type?: 'max-40' | 'max-60' | 'max-80' | 'max-100' | 'fixable-by' | 'category';
    toSide?: 'top' | 'right' | 'bottom' | 'left';
    textAlign?: 'center' | 'left';
    size?: 'large' | 'small';
    width?: number;
}

export interface TableColumn {
    id: number;
    title: string;
    keys?: {
        key: string,
        title: string,
        // predefined type is needed for searching and filtering
        // Type enum currently only supports the type EnumeratedState, which is commonly used throughout the app.
        // Might be extended in a future update to handle custom enums
        type: 'string' | 'date' | 'number' | 'boolean' | 'array' | 'enum',
        // make the key not filterable / searchable / sortable
        hidden?: boolean,
    }[];
    renderCell?: (attr: any[]) => string;
    renderRef?: TemplateRef<any>; // TemplateRef allows for rendering of complex cells. Removes wrappers and padding
    config?: {
        noMinWidth?: boolean;
        defaultWidth?: number;
        noFilter?: boolean;
        noSort?: boolean;
        noSearch?: boolean;
        shaded?: boolean;
        sticky?: 'left' | 'right';
        squished?: 'left' | 'right';
        icon?: {
            type: string;
            color: string;
            size: 'default' | 'large';
        };
        hoverInfo?: string,
        tooltip?: TooltipConfig;
    };
    actions?: TableCellAction[]
}


type BaseTableCellAction = {
    id: string,
    title: string | ((attr: any[]) => string),
    icon?: string | ((attr: any[]) => string),
    condition?: boolean | ((attr: any[]) => boolean),
    renderAction?: (attr: any[]) => string
}

type TableCellActionWithIcon = Pick<BaseTableCellAction, Exclude<keyof BaseTableCellAction, 'renderAction'>> & { icon: string | ((attr: any[]) => string) };
type TableCellActionWithRenderAction = Pick<BaseTableCellAction, Exclude<keyof BaseTableCellAction, 'icon'>> & { renderAction: (attr: any) => string };

type TableCellAction = TableCellActionWithIcon | TableCellActionWithRenderAction;

export type TableCellActionEvent = {actionId: string, row: number}
export type SubTableCellActionEvent = {actionId: string, subTableContent: Record<string, any>}

interface TableHeaderVM {
    columnHeader: TableColumnHeader;
    column: TableColumn;
    columnSearch: string | null;
    filters: Filter[] | null;
    columnWidth: number | null;
    className: string;
}

// meta info and subrows for rows in matrix, on index 0
type RowMeta = {
    id: number,
    bulkActionDisabled: boolean,
    subrowsEntries?: any[],
    subrows?: any[], 
    // controls visible state of subrows / subTable
    rowToggled?: boolean,
    subTable?: {
        entries: any[],
        columns: TableColumn[],
        config: {
            // collection w # of table cells on the left (prepared for iteration in template)
            fromCol: number[],
            colSpan: number,
            defaultSortByKey?: string,
            defaultSortDirection?: 'asc' | 'desc'
        }
    }
};

@Component({
    selector: 'evc-table',
    template: `
        @if (tableState$ | async; as tableState) {
            @if (enrichedScroll && showScrollAbove && !tableState.showPreloader && !tableState.message) {
                <div
                    class="sync-scroll-x"
                    (scroll)="syncScroll(scrollAbove, 'x')"
                    (mouseenter)="setScrollSource($event)" 
                    (mouseleave)="setScrollSource($event)" 
                    (mousedown)="setScrollSource($event)" 
                    (mouseup)="setScrollSource($event)"
                    #scrollAbove
                >
                    <div [style.width]="(tableWidth$ | async) + 'px'"></div>
                </div>
            }
        }
        <div 
            class="table-wrapper"
            (scroll)="syncScroll(tableWrapper, 'both')"
            (mouseenter)="setScrollSource($event)" 
            (mouseleave)="setScrollSource($event)" 
            (mousedown)="setScrollSource($event)" 
            (mouseup)="setScrollSource($event)"
            (window:resize)="updateTableWrapperRect()"
            [class.has-bulk-actions-no-pagination]="maxPages == 1 && ((bulkActionOptions$ | async) ?? []).length > 0"
            [class.no-scrolling]="!scrollable"
            [class.box-shadow-rows]="boxShadow"
            #tableWrapper
        >
            <table 
                (resized)="onTableResize($event)"
                [class.resizeable]="resizeable"
                #tableRef
            >
                <tbody class="sticky-top">
                    <tr class="table-header-row">
                        <!-- optional table header for bulk actions -->
                        <th
                            *ngIf="((bulkActionOptions$ | async) || []).length > 0"
                            class="sticky-checkbox bulk-action-placeholder"
                            [style.backgroundColor]="backgroundColor"
                        >
                        </th>
                        <!-- main table headers -->
                        @for (header of tableHeaders$ | async; track $index) {
                            <th
                                *ngIf="resizeable"
                                [class.has-min-width]="!header.column.config?.noMinWidth"
                                [class.sticky-left]="header.column.config?.sticky == 'left'"
                                [class.sticky-right]="header.column.config?.sticky == 'right'"
                                [style.width.px]="header.column.config?.defaultWidth ? header.column.config?.defaultWidth : null"
                                resizableColumn
                                (columnWidth)="newColumnWidth$.next({columnId: header.column.id, width: $event})"
                                [style.min-width.px]="header.columnWidth"
                                [style.backgroundColor]="header.column.config?.sticky ? backgroundColor : null"
                            >
                                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                            </th>

                            <th 
                                *ngIf="!resizeable"
                                [class.has-min-width]="!header.column.config?.noMinWidth"
                                [class.sticky-left]="header.column.config?.sticky == 'left'"
                                [class.sticky-right]="header.column.config?.sticky == 'right'"
                                [style.backgroundColor]="header.column.config?.sticky ? backgroundColor : null"
                                [style.width.px]="header.column.config?.defaultWidth ? header.column.config?.defaultWidth : null"
                            >
                                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                            </th>

                            <!-- table header content -->
                            <ng-template #headerTemplate>
                                <table-header
                                    [columnHeader]="header.columnHeader"
                                    [sortByKey]="sortBy$ | async"
                                    [sortOrder]="sortDirection$ | async"
                                    [isDragging]="false"
                                    [columnSearch]="header.columnSearch"
                                    [tableWrapper]="tableWrapper"
                                    [filters]="header.filters"
                                    [noSort]="header.column.config?.noSort"
                                    [noSearch]="header.column.config?.noSearch"
                                    [squished]="header.column.config?.squished"
                                    [icon]="header.column.config?.icon"
                                    [hoverInfo]="header.column.config?.hoverInfo"
                                    (onSort)="updateSortBy($event)"
                                    (onColumnSearch)="searchInColumn$.next({columnId: header.column.id, search: $event})"
                                    (onFilterSelection)="newFilterSelection$.next($event)"
                                    (onFilterReset)="newFilterSelection$.next({id: $event.id, value: undefined})"
                                >
                                </table-header>
                            </ng-template>
                        }
                    </tr>
                </tbody>
                <ng-container *ngIf="!(tableState$ | async)?.showPreloader">
                    @for (row of tableMatrix$ | async; track $index) {
                        <ng-container *ngIf="row[0] as rowMeta">
                            <tbody>
                                <tr
                                    [class.strong-separation]="strongSeparation"
                                    [class.synced]="(syncItem$ | async) == rowMeta.id"
                                >
                                    <!-- optional selection for bulk actions -->
                                    @if (((bulkActionOptions$ | async) || []).length > 0) {
                                        <td class="sticky-checkbox">
                                            @if (bulkActionRows$ | async; as bulkActionRows) {
                                                @if (!rowMeta.bulkActionDisabled) {
                                                    <input 
                                                        type="checkbox" 
                                                        name="row-{{ rowMeta.id }}" 
                                                        id="row-{{ rowMeta.id }}" 
                                                        (change)="toggleBulkAction$.next(rowMeta.id)"
                                                        [checked]="bulkActionRows.includes(rowMeta.id) ? true : null"
                                                    >
                                                }
                                            }
                                        </td>
                                    }
                                    <!-- matrix rows, start from index 1, index 0 always contains row id -->
                                    <ng-container *ngFor="let cell of row | slice: 1; index as i">
                                        <ng-container *ngIf="(tableHeaders$ | async)?.[i] as tableHeader">
                                            <td 
                                                [class.sticky-left]="tableHeader.column.config?.sticky == 'left'"
                                                [class.sticky-right]="tableHeader.column.config?.sticky == 'right'"
                                                [class.shaded]="tableHeader.column.config?.shaded"
                                                [class.no-padding]="cell.renderRef !== undefined"
                                                [class.sync-active]="syncActive"
                                                [class.strong-separation]="strongSeparation"
                                                (click)="syncActive && syncRow(rowMeta.id)"
                                            >
                                                @if (cell.renderRef) {
                                                    <!-- Render provided template -->
                                                    <ng-container *ngTemplateOutlet="cell.renderRef; context: {$implicit: cell.value}"></ng-container>
                                                }
                                                <div class="flex-row align-items-center">
                                                    <!-- Cell Actions -->
                                                    @if (tableHeader.column.actions) {
                                                        <div class="actions d-flex">
                                                            @for (action of cell.actions; track action.id) {
                                                                @if (action.condition == undefined || action.condition) {
                                                                    <button
                                                                        [tooltip]="action.title"
                                                                        [size]="'small'"
                                                                        type="button"
                                                                        (click)="onCellAction.emit({actionId: action.id, row: rowMeta.id})"
                                                                    >
                                                                        <div
                                                                            [innerHTML]="action.label"
                                                                        ></div>
                                                                    </button>
                                                                }
                                                            }
                                                        </div>
                                                    }
                                                    <!-- Cell Content -->
                                                    @if (cell.tooltip && cell.label != '') {
                                                        <!-- With Tooltip -->
                                                        <div
                                                            class="has-tooltip {{ tableHeader.className }}"
                                                            [innerHTML]="cell.label"
                                                            [tooltip]="cell.tooltip?.text || null"
                                                            [toSide]="cell.tooltip?.toSide || 'top'"
                                                            [size]="cell.tooltip?.size || 'large'"
                                                            [textAlign]="cell.tooltip?.textAlign || 'center'"
                                                            [width]="cell.tooltip?.width || undefined"
                                                        ></div>
                                                    } @else if (!cell.renderRef && cell.label != '') {
                                                        <!-- Without Tooltip -->
                                                        <div
                                                            class="cell-content {{ tableHeader.className }}"
                                                            [innerHTML]="cell.label"
                                                        ></div>
                                                    }
                                                </div>
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                    <td 
                                        *ngIf="rowMeta.subrowsEntries || rowMeta.subTable"
                                        class="sticky-right toggle-subrow"
                                    >
                                        <button 
                                            class="expand-row" 
                                            [class.expanded]="toggleSubrowIcons == null && rowMeta.rowToggled"
                                            [class.custom-icons]="toggleSubrowIcons !== null"
                                            [class.active]="toggleSubrowIcons !== null && rowMeta.rowToggled"
                                            (click)="toggleSubrow$.next(rowMeta.id)"
                                        >
                                            <!-- use special icons -->
                                            <ng-container *ngIf="toggleSubrowIcons !== null; else toggleSubrow">
                                                <span class="material-icon">{{ toggleSubrowIcons[rowMeta.rowToggled ? 1 : 0] }}</span>
                                            </ng-container>
                                            <!-- default chevron -->
                                            <ng-template #toggleSubrow>
                                                <span class="material-icon">expand_more</span>
                                            </ng-template>
                                        </button>
                                    </td>
                                    <!-- adds a '+' at the end of a row to show a modal with custom content -->
                                    <td *ngIf="modal" class="sticky-right">
                                        <div class="open-modal">
                                            <div class="material-icon">add</div>
                                            <ng-content *ngTemplateOutlet="templateRef; context:{row: row}"></ng-content>
                                        </div>
                                    </td>
                                </tr>
                                <!-- S U B R O W S -->
                                <ng-container *ngIf="rowMeta.subrows && rowMeta.rowToggled === true">
                                    <ng-container *ngFor="let row of rowMeta.subrows">
                                        <tr [class.strong-separation]="strongSeparation">
                                            <ng-container *ngFor="let cell of row; index as i">
                                                <ng-container *ngIf="(tableHeaders$ | async)?.[i] as tableHeader">
                                                    <td 
                                                        [class.sticky-left]="tableHeader.column.config?.sticky == 'left'"
                                                        [class.sticky-right]="tableHeader.column.config?.sticky == 'right'"
                                                    >
                                                        <div
                                                            [class]="tableHeader.className"
                                                            [innerHTML]="cell.label"
                                                            [class.has-tooltip]="cell.tooltip"
                                                            [tooltip]="cell.tooltip?.text || null"
                                                            [toSide]="cell.tooltip?.toSide || 'top'"
                                                            [size]="cell.tooltip?.size || 'large'"
                                                            [textAlign]="cell.tooltip?.textAlign || 'center'"
                                                            [width]="cell.tooltip?.width || undefined"
                                                        ></div>
                                                    </td>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </ng-container>

                                <!-- S U B T A B L E -->
                                @if (rowMeta.subTable && rowMeta.rowToggled === true) {
                                    <tr>
                                        <td *ngFor="let i of rowMeta.subTable.config.fromCol"></td>
                                        <td [attr.colspan]="rowMeta.subTable.config.colSpan">
                                            <div class="subtable" [@heightAnimation]>
                                                <evc-table
                                                    [columns]="rowMeta.subTable.columns"
                                                    [rows]="rowMeta.subTable.entries"
                                                    [strongSeparation]="true"
                                                    [resizeable]="false"
                                                    [scrollable]="false"
                                                    [defaultSortByKey]="rowMeta.subTable.config.defaultSortByKey ?? null"
                                                    [defaultSortDirection]="rowMeta.subTable.config.defaultSortDirection ?? null"
                                                    (onCellAction)="onSubTableAction($event, rowMeta.subTable)"
                                                >
                                                </evc-table>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </ng-container>
                    }
                </ng-container>
            </table>

            @if (tableState$ | async; as tableState) {
                @if (enrichedScroll && showScrollBeside && !tableState.showPreloader && !tableState.message) {
                    <div class="scrollbar-wrapper">
                        <div
                            class="sync-scroll-y"
                            (scroll)="syncScroll(scrollBeside, 'y')"
                            (mouseenter)="setScrollSource($event)" 
                            (mouseleave)="setScrollSource($event)" 
                            (mousedown)="setScrollSource($event)" 
                            (mouseup)="setScrollSource($event)"
                            #scrollBeside
                        >
                            <div [style.height]="(tableHeight$ | async) + 'px'"></div>
                        </div>
                    </div>
                }
            }

            <!-- S T A T E S -->
            <ng-container *ngIf="tableState$ | async as state">
                <div 
                    *ngIf="state.message || state.showPreloader"
                    class="state-container p-32"
                >
                    <div 
                        *ngIf="state.message || state.icon"
                        class="flex-row align-items-center justify-content-center"
                    >
                        <span 
                            *ngIf="state.icon"
                            class="material-icon"
                        >{{ state.icon }}</span>
                        <p
                            *ngIf="state.message"
                            class="p-16"
                        >{{ state.message }}</p>
                    </div>

                    <div class="text-center">
                        <app-preloader
                            *ngIf="state.showPreloader"
                            [type]="'squares'"
                        >
                        </app-preloader>
                    </div>
                </div>
            </ng-container>
        </div>

        @if (tableState$ | async; as tableState) {
            @if (enrichedScroll && showScrollBelow && !tableState.showPreloader && !tableState.message) {
                <div 
                    class="sync-scroll-x"
                    (scroll)="syncScroll(scrollBelow, 'x')"
                    (mouseenter)="setScrollSource($event)" 
                    (mouseleave)="setScrollSource($event)" 
                    (mousedown)="setScrollSource($event)" 
                    (mouseup)="setScrollSource($event)"
                    #scrollBelow
                >
                    <div [style.width]="(tableWidth$ | async) + 'px'"></div>
                </div>
            }
        }

        <!-- P A G I N A T I O N -->
        @if (maxPages > 1 && (page$ | async); as currentPage) {
            <div
                class="flex-row align-items-center justify-content-center pb-16 pt-16 pagination"
                [class.bulk-actions-toggled]="((bulkActionRows$ | async) ?? []).length > 0"
            >
                <button 
                    class="btn-chevron chevron-left" 
                    (click)="nextPage$.next(currentPage - 1)"
                    [disabled]="currentPage === 1"
                ></button>
                <input 
                    #inputRef
                    type="number"
                    min="1"
                    [max]="maxPages"
                    [value]="currentPage"
                    (input)="nextPage$.next(inputRef.valueAsNumber)"
                >
                <p class="copy pl-16"> {{ 'DASHBOARD.TABLE.OF_PAGES' | translate }} {{ maxPages }}</p>
                <button 
                    class="btn-chevron chevron-right" 
                    (click)="nextPage$.next(currentPage + 1)"
                    [disabled]="currentPage === maxPages"
                ></button>
            </div>
        }

        <!-- Bulk Actions -->
        @if (bulkActionsVM$ | async; as baVM) {
            <evc-table-bulk-panel
                [options]="baVM.actions"
                [selectedIds]="baVM.selectedRows"
                (selectedIdsChange)="setBulkActionRows($event)"
                (onAction)="emitBulkAction($event)"
            />
        }
    `,
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInUpAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(calc(-100% + 10px))', opacity: 0 }),
                animate('.25s ease-out',
                    style({ transform: 'translateY(-100%)', opacity: 1 })
                )
            ]),
            transition(':leave', [
                style({ transform: 'translateY(-100%)', opacity: 1 }),
                animate('.25s ease-in',
                    style({ transform: 'translateY(calc(-100% + 10px))', opacity: 0 })
                )
            ])
        ]),
        trigger('changeHeight', [
            transition(':enter', [
                style({ height: '0px' }),
                animate('.2s ease-out', style({ height: '50px' }))
            ]),
            transition(':leave', [
                style({ height: '50px' }),
                animate('.2s ease-in', style({ height: '0px' }))
            ])
        ]),
        trigger('heightAnimation', [
            transition(':enter', [
                style({ height: 0 }),
                animate('.2s ease-out', style({ height: '*' }))
            ]),
            transition(':leave', [
                style({ height: '*' }),
                animate('.25s ease-in', style({ height: 0 }))
            ])
        ])
    ]
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    // ElementRefs needed for synched scroll elements (enriched scroll)
    private _tableRef = viewChild<ElementRef>('tableRef')
    private _tableWrapper = viewChild<ElementRef>('tableWrapper');
    private _scrollAbove = viewChild<ElementRef>('scrollAbove');
    private _scrollBelow = viewChild<ElementRef>('scrollBelow');
    private _scrollBeside = viewChild<ElementRef>('scrollBeside');
    // current scroll positions
    public scrollLeft$ = new BehaviorSubject<number>(0);
    public scrollTop$ = new BehaviorSubject<number>(0);
    // dimensions of nested table
    tableHeight$: Subject<number> = new BehaviorSubject<number>(0);
    tableWidth$: Subject<number> = new BehaviorSubject<number>(0);
    // dimensions of bounding wrapper
    tableWrapperRect: {wrapperStart: number, wrapperEnd: number} | undefined;
    // active scroll source element
    private _scrollSourceEl$ = new BehaviorSubject<Element | undefined>(undefined);
    // timer ref for Firefox scrollbar fix
    private _FFTimer: any;

    @ContentChild(TemplateRef) templateRef!: TemplateRef<any>;
    /* I N P U T S */
    // needs id to use state management
    @Input() tableId: string | undefined;
    // Columns
    public columns$ = new BehaviorSubject<TableColumn[]>([]);
    @Input() set columns(columns: TableColumn[] | null) {
        this.columns$.next(columns?.sort((a, b) => a.id - b.id) ?? []);
        // set initial sortBy (is overwritten when "defaultSortByKey" is set)
        if (columns && this.sortBy$.getValue() === '') {
            const columnKeys = columns.flatMap((column) => column.keys);
            if (!columnKeys || columnKeys.length == 0) return
            this.sortBy$.next(columnKeys[0]!.key)
        }
    }
    // set sortBy key
    @Input() set defaultSortByKey(key: string | null) {
        if (key) this.sortBy$.next(key)
    };
    // set sort direction
    @Input() set defaultSortDirection(direction: 'asc' | 'desc' | null) {
        if (direction) this.sortDirection$.next(direction)
    };
    // columns with additional attr
    public tableHeaders$: Observable<TableHeaderVM[]>;
    // Rows / Entries - subrows or subTable can be added to each row
    private _rows$ = new BehaviorSubject<Record<string, any & {
        subrows?: Record<string, any>,
        subTableEntries?: any[],
        subTableColumns?: TableColumn[],
        subTableConfig?: {
            fromCol: number,
            colSpan: number,
            defaultSortByKey?: string,
            defaultSortDirection?: 'asc' | 'desc'
        }
    }>[]>([]);
    @Input() set rows(rows: Record<string, any & {
        subrows?: Record<string, any>
    }>[] | null) {
        // reset toggled bulk action and subrows
        this.setBulkActionRows$.next([])
        this._setToggledSubrows$.next([])
        // set initial index as fixed id in this table
        const indexedRows = [...(rows ?? [])].map((row, index) => {
            row['_row_id'] = index;
            return row
        });
        this._rows$.next(indexedRows);
    }
    // matrix of formatted rows
    public tableMatrix$: Observable<[RowMeta, ...any[]][]>;
    // column filters
    public filters$: Observable<Filter[] | null>;
    // sets new filter values
    public newFilterSelection$ = new BehaviorSubject<{id: string, value: any}>({id: '', value: null});
    // accumulates all selected filter values
    private _selectedFilterValues$: Observable<{[id: string]: any}>;
    // resizeable - whether columns can be resized
    @Input() resizeable: boolean = true;
    // scrollable - whether table can be scrolled or fit into parent
    @Input() scrollable: boolean = true;
    // enrichedScroll - will constantly show synced scroll bars selected sides of the tableWrapper
    @Input() enrichedScroll: boolean = false;
    // showScrollAbove - (enrichedScroll = true) will not show scroll bar above if set to false
    @Input() showScrollAbove: boolean = true;
    // showScrollBelow - (enrichedScroll = true) will not show scroll bar below if set to false
    @Input() showScrollBelow: boolean = true;
    // showScrollBeside - (enrichedScroll = true) will not show scroll bar beside if set to false
    @Input() showScrollBeside: boolean = true;
    // modal - will add a '+' at the end of each row with custom content
    @Input() modal: boolean = false;
    // bulkActions - array of available bulk actions, default none, rows can be selected if set
    public bulkActionOptions$ = new BehaviorSubject<BulkSelectAction[]>([]);
    @Input() set bulkActions(options: BulkSelectAction[] | null) {
        this.bulkActionOptions$.next(options ?? [])
    }
    // toggle state of single row
    public toggleBulkAction$ = new BehaviorSubject<number | null>(null);
    // overwrites all currently selected bulk action rows
    public setBulkActionRows$ = new BehaviorSubject<number[] | null>(null);
    // all rows
    public bulkActionRows$: Observable<number[]>;
    // helps handling bulk action data in template
    public bulkActionsVM$: Observable<{
        actions: BulkSelectAction[],
        selectedRows: number[]
    }>;
    public showBulkActions: boolean = false;
    // toggle subrows by row id
    public toggleSubrow$ = new BehaviorSubject<number | null>(null);
    // overwrites all currently toggled subrows
    private _setToggledSubrows$ = new BehaviorSubject<number[] | null>(null);
    // all currently toggled subrows
    private _toggledSubrows$: Observable<number[]>;
    // set of icons to be displayed instead of the default chevron to toggle subrows [inactive, active]
    @Input() toggleSubrowIcons: [string, string] | null = null
    // state - 'loading' 'error' 'success'
    public parentState$ = new BehaviorSubject<'loading' | 'error' | 'success' | null>(null);
    @Input() set state(state: 'loading' | 'error' | 'success' | null) {
        this.parentState$.next(state)
    }
    // internal state (e.g. after filtering or sorting)
    private _tableState$: Observable<'empty' | 'success' | null>;
    // controls text, icon and preloader
    public tableState$: Observable<{
        icon?: string,
        message?: string,
        showPreloader?: boolean
    } | null>;
    // amount of entries per page, set to null to disable pagination
    @Input() perPage: number | null = 100;
    // requests new page
    public nextPage$ = new BehaviorSubject<number>(1);
    // current page
    public page$: Observable<number>;
    // sets the current page
    @Input() set currentPage(page: number | null) {
        if (page != null) this.nextPage$.next(page);
    }
    // whether items should be synced / table rows clickable
    @Input() syncActive: 'sessions' | 'errors' | null = null;
    // active items to sync between table and timeline
    public syncItem$ = new BehaviorSubject<number | null>(null);
    @Input() set syncItem(item: Record<string, any> | null) {
        if (!item) {
            this.syncItem$.next(null);
            return;
        }
        const toCompare = this.syncActive == 'sessions' 
            ? ['connectorId', 'startDate'] 
            : ['connectorId', 'date', 'errorCode', 'errorModelInterpretation'];
        const row = this._rows$.value.find(row =>
            toCompare.every(attr => row[attr] === item[attr])
        );
        this.syncItem$.next(row ? row['_row_id'] : null);
    }
    // max num of pages
    public maxPages: number = 1;
    // general search string
    private _searchQuery$ = new BehaviorSubject<string | null>(null)
    @Input() set searchQuery(search: string | null) {
        this._searchQuery$.next(search && search.length > 0 ? search : null)
    }
    // disable filter creation when rows > threshold
    @Input() disableFiltersThreshold: null | number = null;

    // adds stronger borders between rows to make their separation clearer
    @Input() strongSeparation: boolean = false;
    // adds box-shadow to each table row (+ slight padding to the container to show overflowing shadow)
    @Input() boxShadow: boolean = false;

    // needed to hide overlapping elements with same color as background
    public backgroundColor: string = '#ffff';
    @Input() set backgroundColorVar(colorVar: string) {
        this.backgroundColor = this._stateHelper.getVar(colorVar);
    };

    public searchInColumn$ = new BehaviorSubject<{columnId: TableColumn['id'], search: string | null} | null>(null);
    // holds all column widths, either updated directly or through table repo
    private _columnWidths$ = new BehaviorSubject<{[id: TableColumn['id']]: number | null}>({});
    public newColumnWidth$ = new BehaviorSubject<{columnId: TableColumn['id'], width: number | null}>({columnId: -1, width: null });

    /* O U T P U T S */

    // onBulkAction
    @Output() onBulkAction = new EventEmitter<{action: string, rows: number[]}>();
    // emits when new selection for bulk action is made
    @Output() onBulkActionSelectionChange = new EventEmitter<number[]>()
    // emits when elements in action cell are clicked
    @Output() onCellAction = new EventEmitter<TableCellActionEvent>();
    // emits when action elements of subtable are triggered
    @Output() onSubTableCellAction = new EventEmitter<SubTableCellActionEvent>();

    // emits when page changed
    @Output() currentPageChange = new EventEmitter<number>();
    // emits when row is selected (active)
    @Output() syncItemChange = new EventEmitter<Record<string, any> | null>();
    // emits all currently visible data in table after fitlers apply
    @Output() onNewDataFiltered = new EventEmitter<any[]>();


    public sortBy$ = new BehaviorSubject<string>('');
    public sortDirection$ = new BehaviorSubject<'asc' | 'desc'>('asc');

    constructor(
        public tablesRepo: tablesRepository,
        private _stateHelper: StateHelperService,
        private _translate: TranslateService
    ) {
        this.page$ = this.nextPage$.pipe(
            distinctUntilChanged(),
            map((newPage) => {
                // apply bounds (min 1, max = maxPages)
                newPage = newPage > this.maxPages 
                    ? this.maxPages 
                    : newPage < 1 
                        ? 1
                        : newPage;

                // reset to first page if NaN
                newPage = isNaN(newPage) ? 1 : newPage;

                // emit new page to parents
                this.currentPageChange.emit(newPage);

                return newPage
            }),
            shareReplay()
        )

        // collect all toggled rows for bulk actions
        this.bulkActionRows$ = this.toggleBulkAction$.pipe(
            scan(
                (all: number[], current: number | null) => {
                    if (current == null) return all
                    return all.includes(current)
                        ? all.filter(x => x !== current) // remove from array
                        : [...all, current]
                }, []
            ),
            tap(this.onBulkActionSelectionChange),
            shareReplay()
        )

        this.scrollLeft$.pipe(
            takeUntilDestroyed(),
            debounceTime(1),
            withLatestFrom(this._scrollSourceEl$),
            tap(([value, source]) => {
                if (!this.enrichedScroll) return;
                const scrollEls = [
                    this._scrollAbove()?.nativeElement,
                    this._tableWrapper()?.nativeElement,
                    this._scrollBeside()?.nativeElement,
                    this._scrollBelow()?.nativeElement
                ].filter(el => el && el !== source);
                scrollEls.forEach(el => el.scrollLeft = value);
            })
        ).subscribe();

        this.scrollTop$.pipe(
            takeUntilDestroyed(),
            debounceTime(1),
            withLatestFrom(this._scrollSourceEl$),
            tap(([value, source]) => {
                if (!this.enrichedScroll) return;
                const scrollEls = [
                    this._scrollAbove()?.nativeElement,
                    this._tableWrapper()?.nativeElement,
                    this._scrollBeside()?.nativeElement,
                    this._scrollBelow()?.nativeElement
                ].filter(el => el && el !== source);
                scrollEls.forEach(el => el.scrollTop = value);
            })
        ).subscribe();

        // handles set events
        this.setBulkActionRows$.pipe(
            takeUntil(this._destroying$),
            withLatestFrom(this.bulkActionRows$),
            tap(([set, current]) => {
                if (set == null) return;

                // either toggle all entries from set which are not currently selected
                // or toggle all current rows
                const toToggle = set.length > 0 
                    ? set?.filter((id) => !current.includes(id))
                    : current;

                toToggle?.forEach((id) => {
                    this.toggleBulkAction$.next(id)
                })
            })
        ).subscribe()

        // collect all toggled subrows
        this._toggledSubrows$ = this.toggleSubrow$.pipe(
            scan(
                (all: number[], current: number | null) => {
                    if (current == null) return all
                    return all.includes(current)
                        ? all.filter(x => x !== current) // remove from array
                        : [...all, current]
                }, []
            )
        )

        this._setToggledSubrows$.pipe(
            takeUntil(this._destroying$),
            withLatestFrom(this._toggledSubrows$),
            tap(([set, current]) => {
                if (set == null) return;

                // either toggle all entries from set which are not currently selected
                // or toggle all current rows
                const toToggle = set.length > 0 
                    ? set?.filter((id) => !current.includes(id))
                    : current;

                toToggle?.forEach((id) => {
                    this.toggleSubrow$.next(id)
                })
            })
        ).subscribe()

        // combine all selected filters in a key value pair obj
        this._selectedFilterValues$ = this.newFilterSelection$.pipe(
            // filter out empty filters (empty string as filter key)
            filter((filter) => filter.id.length > 0),
            // reduce calls
            debounceTime(10),
            // reset to first page
            tap(() => this.nextPage$.next(1)),
            map((newSelection) => {
                let obj: any = new Object();
                obj[newSelection.id] = newSelection.value;
                return obj
            }),
            scan((acc, curr) => Object.assign({}, acc, curr), {}),
            startWith({}),
            shareReplay()
        )

        // resets page with new searches
        combineLatest({
            globalSearch: this._searchQuery$,
            search: this.searchInColumn$
        }).pipe(
            takeUntil(this._destroying$),
            filter(({globalSearch, search}) => globalSearch !== null || search !== null),
            tap(_ => this.nextPage$.next(1)),
        ).subscribe()

        // combine all column widths in a key value pair obj
        this.newColumnWidth$.pipe(
            takeUntil(this._destroying$),
            map((newWidth) => {
                let obj: any = new Object();
                obj[newWidth.columnId] = newWidth.width;
                return obj
            }),
            tap((singleWidth) => {
                // update in store if available
                if (this.tableId) {
                    this.tablesRepo.updateSingleWidth(this.tableId, singleWidth)
                }
            }),
            scan((acc, curr) => Object.assign({}, acc, curr), {}),
            tap((columnWidths) => {
                // update accumulated value in component store if repo is not available
                if (!this.tableId) {
                    this._columnWidths$.next(columnWidths)
                }
            })
        ).subscribe()

        // create filters based on given values in table
        // TODO: Only create filters (or even single filter) once user opens filter dropdown
        this.filters$ = combineLatest([
            this.columns$,
            this._rows$,
            this._selectedFilterValues$
        ]).pipe(
            map(([columns, rows, selectedFilterValues]) => {

                if (this.disableFiltersThreshold !== null && rows.length > this.disableFiltersThreshold) return [];

                const allKeys = columns
                    .filter((col) => !col.config || !col.config.noFilter)
                    .flatMap((col) => col.keys)

                const filters = allKeys.map((keyObj) => {
                    if (!keyObj) return;

                    const uniqueAndFilteredValues = rows.reduce((acc: any[], row) => {
                        const value = row[keyObj.key];
                        if (value && !acc.includes(value)) {
                            acc.push(value);
                        }
                        return acc;
                    }, [] as any[]);

                    let filter: any = {
                        id: keyObj.key,
                        label: keyObj.title
                    }

                    // create filter options with empty values
                    switch (keyObj.type) {
                        case 'string': case 'enum':
                            if (uniqueAndFilteredValues.length == 0) return;
                            filter['options'] = uniqueAndFilteredValues.map((value) => {
                                return {
                                    label: value.toString(),
                                    value: value.toString()
                                }
                            });
                            filter['type']  = 'select-multiple';
                            filter['value'] = [];
                            break;
                        case 'date':
                            if (uniqueAndFilteredValues.length == 0) return;
                            const timestamps = uniqueAndFilteredValues.map((date) => {
                                return new Date(date).getTime()
                            });
                            const minDate = new Date(Math.min(...timestamps));
                            const maxDate = new Date(Math.max(...timestamps));

                            filter['options']   = [{value: minDate}, {value: maxDate}]
                            filter['type']      = 'date-range';
                            break;
                        case 'boolean':
                            if (uniqueAndFilteredValues.length == 0) return;
                            filter['options'] = [
                                {
                                    label: 'True',
                                    value: 'true'
                                },
                                {
                                    label: 'False',
                                    value: 'false'
                                }
                            ];
                            filter['type'] = 'select-single-radio';
                            break;
                        case 'number':
                            if (uniqueAndFilteredValues.length == 0) return;
                            const min = Math.floor(Math.min(...uniqueAndFilteredValues) * 100) / 100;
                            const max = Math.ceil(Math.max(...uniqueAndFilteredValues) * 100) / 100;
                            const diff = Math.floor(max - min);
                            const diffLength = diff.toString().length;
                            // set step to 10 to the power of with diffLength - 3
                            const step = diffLength > 2 ? Math.pow(10, diffLength - 3) : 1;

                            if (max - min <= step) return;

                            filter['rangePickerOptions'] = {
                                min,
                                max,
                                step,
                                firstStart: min,
                                secondStart: max
                            };
                            filter['type'] = 'range';
                            filter['value'] = [];
                            break;
                        case 'array':
                            if (uniqueAndFilteredValues.length == 0) return;
                            let set = new Set<any>();
                            uniqueAndFilteredValues.forEach((innerArray) => {
                              innerArray.forEach((value: any) => set.add(value));
                            });
                            const uniqueValuesArray = Array.from(set).sort();
                            filter['options'] = uniqueValuesArray.map((value) => {
                                return {
                                    label: value.toString(),
                                    value: value.toString()
                                }
                            });
                            filter['type']  = 'select-multiple';
                            filter['value'] = [];
                            break;
                    }

                    // if available, overwrite with current selection
                    const activeSelection = selectedFilterValues[keyObj.key];
                    filter['value'] = activeSelection !== undefined ? activeSelection : filter['value'];

                    // if only one option to filter - don't show filter
                    if (filter.options?.length <= 1) return;
                    
                    return filter
                })
                const nonEmptyFilters = filters.filter((filter) => filter);
                const uniqueAndNonEmptyFilters = Array.from(
                    new Map(nonEmptyFilters.map((filter) => [filter.id, filter])).values()
                );
                return uniqueAndNonEmptyFilters;
            })
        );

        const throttledSearch$ = this.searchInColumn$.pipe(
            // throttle inputs
            debounceTime(200),
            // start with null again, else this subject would not emit until first 
            // debounceTimer is completed, resulting in a rendering delay of the table
            startWith(null)
        )

        // applies search, filters and sorts before paginating
        const filteredRows$ = combineLatest({
            columns: this.columns$,
            rows: this._rows$,
            globalSearch: this._searchQuery$,
            search: throttledSearch$,
            sortBy: this.sortBy$,
            sortDirection: this.sortDirection$,
            filters: this.filters$
        }).pipe(
            map(({columns, rows, globalSearch, search, sortBy, sortDirection, filters}) => {
                const selectedFilters = filters?.filter((filter) => {
                    return filter.value !== undefined && filter.value.length > 0
                })

                if (selectedFilters) {
                    rows = rows.filter((row) => {
                        // apply filters
                        return selectedFilters.every((filter) => {
                            const rowValue = row[filter.id];
                            let match = false;

                            switch (filter.type) {
                                case 'select-multiple': case 'select-single-radio':
                                    if (Array.isArray(rowValue)) {
                                        match = filter.value.every((value: any) => rowValue.includes(value));
                                    } else {
                                        match = filter.value.includes(rowValue);
                                    }
                                    break;
                                case 'date-range': case 'date-time-range':
                                    const interval = {
                                        start: new Date(filter.value[0]), 
                                        end: new Date(filter.value[1])
                                    };
                                    match = isWithinInterval(new Date(rowValue), interval);
                                    break;
                                case 'range':
                                    match = (rowValue >= filter.value[0] && filter.value[1] >= rowValue);
                                    break;
                            }
                            return match
                        })
                    })
                }

                // apply global search over all columns
                if (globalSearch) {
                    // filter through all columns
                    rows = rows.filter((row) => columns.some((column) => {
                        let cellString = '';
                        let value = column.keys?.map((keyObj) => row[keyObj.key]);

                        if (column) {
                            cellString = this.getCellContent(column, value ?? []);
                            cellString = this.stripHTML(cellString);
                        }

                        return cellString.toLowerCase().includes(globalSearch.toLowerCase());
                    }))
                }

                // apply search
                if (search && search.search) {
                    let searchColumn = columns.find((column) => column.id == search.columnId);
                    // filter through single column
                    rows = rows.filter((row) => {
                        let cellString = '';
                        let value = searchColumn?.keys?.map((keyObj) => row[keyObj.key]);

                        if (searchColumn) {
                            cellString = this.getCellContent(searchColumn, value ?? []);
                            cellString = this.stripHTML(cellString);
                        }

                        return cellString.toLowerCase().includes(search.search!.toLowerCase());
                    })
                }

                // apply sorting
                if (sortBy && sortDirection) {
                    const fullKey = columns.flatMap((column) => column.keys)
                        .find((key) => key?.key == sortBy)
                    const sort = new Sort();
                    rows.sort(sort.startSort(sortBy, sortDirection, fullKey?.type, '', 'firstAvailable'))
                }

                // emit filtered rows to parent
                this.onNewDataFiltered.emit(rows);

                return rows
            })
        )

        // internal state based on results length
        this._tableState$ = filteredRows$.pipe(
            map((results) => {
                return results.length > 0 ? 'success' : 'empty'
            })
        )

        // defines textcontent
        this.tableState$ = combineLatest({
            parentState: this.parentState$,
            tableState: this._tableState$,
            newLang: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            withLatestFrom(this._selectedFilterValues$),
            withLatestFrom(this._searchQuery$),
            withLatestFrom(this.searchInColumn$),
            map(([[[{parentState, tableState}, selectedFilters], searchQuery], searchInColumn]) => {
                if (parentState == 'error') {
                    return {
                        icon: 'warning',
                        message: this._translate.instant('TABLES.ERROR_RETRIEVING_DATA'),
                        showPreloader: false
                    }
                }
                if (parentState == 'loading') {
                    return {
                        showPreloader: true
                    }
                }
                if (parentState == null || parentState == 'success') {
                    if (tableState == 'empty') {
                        const hasSearch = searchQuery !== null || searchInColumn !== null;
                        const hasFilters = selectedFilters && Object.keys(selectedFilters).filter((key) => key.length > 0).length > 0;
                        return {
                            icon: 'manage_search',
                            message: hasFilters && hasSearch 
                                ? this._translate.instant('TABLES.NO_ENTRIES.WITH_FILTERS_AND_SEARCH')
                                : hasFilters 
                                    ? this._translate.instant('TABLES.NO_ENTRIES.WITH_FILTERS')
                                    : hasSearch
                                        ? this._translate.instant('TABLES.NO_ENTRIES.WITH_SEARCH')
                                        : this._translate.instant('TABLES.NO_ENTRIES.DEFAULT')
                        }
                    }
                }
                return {}
            })
        )

        // paginates rows before creating matrix
        const paginatedRows$ = combineLatest({
            rows: filteredRows$,
            page: this.page$
        }).pipe(
            tap(({ rows }) => this.maxPages = Math.ceil(rows.length / (this.perPage ?? rows.length))),
            map(({ rows, page }) =>
                this.perPage !== null
                    ? rows.slice((page - 1) * this.perPage, page * this.perPage)
                    : rows
            )
        )

        // helper for different TableCellAction Types
        const isTableCellActionWithIcon = (action: TableCellAction): action is TableCellActionWithIcon => {
            return (action as TableCellActionWithIcon).icon !== undefined
        }

        // returns array of prepared strings of requested row 
        const getRowContent = (row: Record<string, any>, columns: TableColumn[]): any[] => {
            let rowContent: any[] = [];
            columns.forEach((column) => {
                // html to be displayed in table
                let label: string;
                // original value
                let value = column.keys ? column.keys.map((cell) => row[cell.key]) : [];
                // optional tooltip config
                let tooltipConfig = column.config?.tooltip
                    ? JSON.parse(JSON.stringify(column.config?.tooltip)): undefined;

                label = this.getCellContent(column, value);

                // TODO: Remove hard coded rules in this global component!
                // > Should be handled through a provided render fn for tooltips

                // optional tooltip
                if (tooltipConfig && label && value) {
                    if (!tooltipConfig.text) {
                        tooltipConfig.text = label;
                        
                        // max-{n} rules
                        if (tooltipConfig.type.startsWith('max-')) {
                            const numString = tooltipConfig.type.substring(4);
                            const num = Number(numString);
                            if (isNaN(num)) return;
                            tooltipConfig.text = label.length > num ? label : null;
                            label = label.length > num ? label.substring(0, num) + "..." : label;
                        }

                        // fixable by rule
                        if (tooltipConfig.type === 'fixable-by') {
                            let result = '';
                            if (value[0] === 'Yes') result += this._translate.instant('DETAILS_VIEW.ERRORS.COLUMNS.CHARGING');
                            if (value[0] === 'Yes' && value[1] === 'Yes') result += ' & ';
                            if (value[1] === 'Yes') result += this._translate.instant('DETAILS_VIEW.ERRORS.COLUMNS.RESTART');
                            tooltipConfig.text = result;
                        }

                        // category rule
                        if (tooltipConfig.type === 'category') {
                            if (value[0] === 'update') tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.UPDATE');
                            if (value[0] === 'information') tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.INFORMATION');
                            if (value[0] === 'warning') tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.WARNING');
                            if (value[0] === 'error') tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.ERROR');
                        }
                    };
                }

                // optional cell actions
                let cellActions: (TableCellAction & {label: string})[] = [];
                if (column.actions) {
                    // evaluate condition if function
                    cellActions = column.actions.map(action => {
                        const evaluate = (prop: any, value: any) => 
                            prop instanceof Function ? prop(value) : prop;
                    
                        const title = evaluate(action.title, value);
                        const condition = evaluate(action.condition, value);
                    
                        const label = isTableCellActionWithIcon(action)
                            ? `<span class="material-icon">${evaluate(action.icon, value)}</span>`
                            : action.renderAction(value);
                    
                        return { ...action, title, condition, label };
                    });
                }

                // TODO: Stronger Types for cell content
                rowContent.push({
                    label: label, 
                    value: value, 
                    tooltip: tooltipConfig, 
                    actions: cellActions,
                    renderRef: column.renderRef
                })
            })
            return rowContent
        }

        // pre-format cell values and create result matrix
        const renderMatrix$ = combineLatest({
            columns: this.columns$,
            rows: paginatedRows$
        }).pipe(
            distinctUntilChanged(),
            map(({columns, rows}) => {
                let resultMatrix: [RowMeta, ...any[]][] = [];

                rows.forEach((row) => {
                    // start with config at index 0
                    let rowMeta: RowMeta = {
                        id: row['_row_id'],
                        // 'raw' subrow data in config will be rendered in next observable if subrows are toggled
                        subrowsEntries: row['subrows'],
                        // if row has entry 'bulkActionDisabled: true' store that info here to prevent ba for this row
                        bulkActionDisabled: row['bulkActionDisabled'] ? row['bulkActionDisabled'] : false
                    };

                    if (row['subTableEntries'] && row['subTableColumns'] && row['subTableConfig']) {
                        const subTableConfig = row['subTableConfig'];
                        rowMeta.subTable = {
                            entries: row['subTableEntries'],
                            columns: row['subTableColumns'],
                            config: {
                                fromCol: Array(subTableConfig.fromCol).fill(0),
                                colSpan: subTableConfig.colSpan,
                                defaultSortByKey: subTableConfig.defaultSortByKey,
                                defaultSortDirection: subTableConfig.defaultSortDirection
                            }
                        }
                    }

                    resultMatrix.push([rowMeta, ...getRowContent(row, columns)])
                })

                return resultMatrix
            })
        )

        this.tableMatrix$ = combineLatest({
            matrix: renderMatrix$,
            toggledSubrows: this._toggledSubrows$
        }).pipe(
            withLatestFrom(this.columns$),
            map(([{matrix, toggledSubrows}, columns]) => {
                return matrix.map((row) => {
                    const rowToggled = toggledSubrows.includes(row[0].id);
                    row[0].rowToggled = rowToggled;

                    // only prepare subrow rendering once selected and not prepared before
                    if (rowToggled && row[0].subrowsEntries && !row[0].subrows) {
                        row[0].subrows = row[0].subrowsEntries.map((entry) => getRowContent(entry, columns))
                    }

                    return row
                })
            })
        )

        // create VM for tableHeaders
        this.tableHeaders$ = combineLatest([
            this.columns$,
            this.filters$,
            this.searchInColumn$,
            this._columnWidths$
        ]).pipe(
            map(([columns, filters, searchColumn, columnWidth]) => {
                return columns.map((column) => {
                    const clonedColumn = { ...column, keys: column.keys?.filter((key) => !key.hidden) };
                    const canBeSearched = clonedColumn.keys?.some((keyObj) => keyObj.type !== 'date');
                    const keys = clonedColumn.keys?.map((keyObj) => keyObj.key);
                    const matchingFilters = filters?.filter((filter) => keys?.includes(filter.id));
                    const columnSearch = searchColumn?.columnId == clonedColumn.id ? searchColumn.search : null;
                    const colWidth = columnWidth[clonedColumn.id];
                    // currently the classname is not utilized
                    const className = '';

                    return {
                        columnHeader: {
                            title: clonedColumn.title,
                            keys: clonedColumn.keys ?? [],
                            canBeSearched
                        },
                        column: clonedColumn,
                        columnSearch,
                        filters: matchingFilters ?? [],
                        columnWidth: colWidth,
                        className
                    }
                })
            })
        );

        // combine for template
        this.bulkActionsVM$ = combineLatest({
            actions: this.bulkActionOptions$,
            selectedRows: this.bulkActionRows$
        })
    }

    ngOnInit(): void {
        if (this.tableId) {
            // manually update value of subject in comp from repo if tableId is set
            this.tablesRepo.getTableWidthUpdates(this.tableId).pipe(
                takeUntil(this._destroying$),
                tap(this._columnWidths$)
            ).subscribe()
        };
    }

    ngAfterViewInit(): void {
        if (this.enrichedScroll) {
            this.updateTableWrapperRect()

            // set inital width and height for enriched scrolling
            this.tableWidth$.next(this._tableRef()?.nativeElement.offsetWidth)
            this.tableHeight$.next(this._tableRef()?.nativeElement.offsetHeight)

            // keep scrollbars visible on firefox by constantly updating position
            if (window.navigator.userAgent.includes('Firefox')) {
                const scrollKeeperFF = () => {
                    this._FFTimer = setTimeout(() => {
                        const updatableScrollEls = [
                            this._scrollAbove()?.nativeElement,
                            this._scrollBeside()?.nativeElement,
                            this._scrollBelow()?.nativeElement
                        ].filter(el => el);
                        updatableScrollEls.forEach((el) => {
                            const scrollDirection = el.classList.contains('sync-scroll-x') ? 'scrollLeft' : 'scrollTop';
                            el[scrollDirection] = el[scrollDirection] + 1 // triggers visibility of scrollbar
                            el[scrollDirection] = el[scrollDirection] - 1 // instantly return to initial position
                        });
                        scrollKeeperFF()
                    }, 455)
                }
                scrollKeeperFF()
            }
        }
    }

    // provide more data for tunneled subTable events
    public onSubTableAction(event: TableCellActionEvent, subTable: RowMeta['subTable']) {
        const entry = subTable?.entries[event.row];
        delete entry._row_id
        this.onSubTableCellAction.emit({actionId: event.actionId, subTableContent: entry})
    }

    // get the html content displayed for a certain value
    public getCellContent(column: TableColumn, value: any[]): string {
        // default date formatting
        if (column.keys && column.keys[0].type == 'date' && value[0]) {
            const date = new Date(value[0]);
            value[0] = formatDate(date, 'MMM d, y, HH:mm', 'en');
        }
            
        // default cell renderer
        const baseRenderer = (): string => {
            if (!column.keys || value[0] == null) return '-';
            return value.join('<br>');
        }

        let label: string = '';

        if (column.renderCell !== undefined) {
            try {
                // try to call render fn of column
                label = column.renderCell(value)
            } catch (error) {
                console.warn('[evc-table] renderCell Fn of "'+column.title+'" failed: \n', error)
                // return default format on error
                label = baseRenderer()
            }
        } else if (column.keys) {
            label = baseRenderer()
        }

        return label;
    }

    public updateSortBy(sortInfo: { key: string, direction: 'asc' | 'desc' | null }) {
        let { key, direction } = sortInfo;
        
        const currentSortBy = this.sortBy$.getValue();
        const currentSortDirection = this.sortDirection$.getValue();

        let sortDirection: 'asc' | 'desc';
        if (direction) {
            // set passed direction
            sortDirection = direction;
            // clear sorting if same attribute and direction as previously
            if (currentSortBy == key && currentSortDirection == sortDirection) {
                key = '';
                sortDirection = 'desc';
            }
        } else {
            // toggle sortOrder by default
            sortDirection = currentSortBy == key && currentSortDirection == 'desc' ? 'asc' : 'desc';
        }

        this.sortBy$.next(key)
        this.sortDirection$.next(sortDirection)
    }

    public syncRow(id: number) {
        this._rows$.pipe(
            take(1),
            withLatestFrom(this.syncItem$),
            tap(([rows, item]) => {
                const selectedRow = rows.find(row => row['_row_id'] == id);
                if (item == id) {
                    this.syncItem$.next(null);
                    this.syncItemChange.emit(null);
                    return;
                }
                this.syncItem$.next(id);
                this.syncItemChange.emit(selectedRow);
            })
        ).subscribe();
    }

    // helper function that removes everything html related to only show the actual content
    public stripHTML(html: string): string {
        const regex = /<[^>]*>/g;
        const textContent = html.replace(regex, '');
        return textContent.trim();
    }

    public setScrollSource(event: MouseEvent) {
        if (!this.enrichedScroll) return
        switch (event.type) {
            case 'mouseenter':
            case 'mousedown':
                this._scrollSourceEl$.next(event.target as Element ?? undefined)
                break;
            case 'mouseleave':
            case 'mouseup':
                this._scrollSourceEl$.next(undefined)
                break;
        }
    }

    // updates start and end of wrapping container for further calculations
    public updateTableWrapperRect() {
        if (!this._tableWrapper()) return;
        let rect: DOMRect = this._tableWrapper()?.nativeElement.getBoundingClientRect(),
            tolerance = 80;

        this.tableWrapperRect = {
            wrapperStart: rect.x + tolerance,
            wrapperEnd: rect.x + rect.width - tolerance
        }
    }

    public onTableResize(event: ResizedEvent) {
        // update height and width
        this.tableWidth$.next(event.newRect.width)
        this.tableHeight$.next(event.newRect.height)
    }

    // syncs scroll position of external scroll bars and table
    public syncScroll(sourceEl: Element, axis: 'x' | 'y' | 'both', threshold: number = 10) {
        if (!this.enrichedScroll) return
        if (sourceEl !== this._scrollSourceEl$.getValue()) return
        // only set requested axis, or both
        const wantedX = axis === 'x' || axis === 'both';
        const wantedY = axis === 'y' || axis === 'both';
        const newScrollLeft = wantedX ? sourceEl.scrollLeft : undefined;
        const newScrollTop = wantedY ? sourceEl.scrollTop : undefined;
    
        // check if the difference exceeds the threshold
        const scrollLeftChanged = newScrollLeft !== undefined && Math.abs(newScrollLeft - this.scrollLeft$.getValue()) >= threshold;
        const scrollTopChanged = newScrollTop !== undefined && Math.abs(newScrollTop - this.scrollTop$.getValue()) >= threshold;

        if (scrollLeftChanged) {
            this.scrollLeft$.next(newScrollLeft)
        }

        if (scrollTopChanged) {
            this.scrollTop$.next(newScrollTop)
        }
    }

    public setBulkActionRows(event: (number | string)[] | null | undefined) {
        if (!event) return;
        this.setBulkActionRows$.next(event as number[]);
    }

    public emitBulkAction(event: {action: string, selectedIds: (number | string)[]}) {
        this.onBulkAction.emit({
            action: event.action,
            rows: event.selectedIds as number[]
        })
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined)
        this._destroying$.complete()
    }
}
