import { transition, trigger, useAnimation } from "@angular/animations";
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { BehaviorSubject, filter, map, Observable, ReplaySubject, share, Subscription, switchMap, take, tap } from "rxjs";
import { appRepository } from "src/app/core/stores/app.repository";
import { environment } from "src/environments/environment";
import { inAnimation, outAnimation } from "src/app/core/helpers/animations";
import { GlobalService } from "src/app/core/app-services/global.service";
import { Router } from "@angular/router";
import { User } from "src/app/core/data-backend/models";
import { CustomersConfigService } from "src/app/core/auth-backend/auth-services";
import { AppAuthService } from "src/app/core/app-services/app-auth.service";
import packageJson from "package.json";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/core/data-backend/data-services";

@Component({
    selector: 'app-user-settings',
    template: `
    <div class="container" #containerRef>
        <div
            *ngIf="isOpen"
            class="settings box p-16"
            [@inOutAnim]
            (@inOutAnim.start)="updateDropdownPosition()"
        >
            <div 
                *evcHasPermissions="'routes.admin.manageUsers'"
                class="tile"
                (click)="goToPage('/admin/manage-users')"
                tabindex="1"
            >
                <div class="material-icon">manage_accounts</div>
                {{ 'NAVIGATION.MANAGE_USERS' | translate }}
            </div>

            <div 
                *evcHasPermissions="'routes.admin.manageTenants'"
                class="tile"
                (click)="goToPage('/admin/manage-tenants')"
                tabindex="1"
            >
                <div class="material-icon">groups</div>
                {{ 'NAVIGATION.MANAGE_TENANT_ACCESS' | translate }}
            </div>

            <div
                *evcHasPermissions="'routes.notifications'"
                class="tile"
                (click)="goToPage('/notifications')"
                tabindex="1"
            >
                <div class="material-icon">chat</div>
                {{ 'NAVIGATION.NOTIFICATIONS' | translate }}
            </div>

            <div
                class="tile"
                (click)="selectLanguage$.next(!selectLanguage$.value)"
                tabindex="1"
            >
                <div class="material-icon">language</div>
                {{ 'NAVIGATION.SELECT_LANGUAGE' | translate }}
            </div>
            
            <div
                *ngIf="((this.appRepo.availableCustomers$ | async) || []).length > 1"
                class="tile"
                (click)="appAuthService.switchCustomer()"
                tabindex="1"
            >
                <div class="material-icon">switch_account</div>
                {{ 'NAVIGATION.SWITCH_CUSTOMER' | translate }}
            </div>

            <div
                class="tile"
                (click)="appAuthService.logout()"
                tabindex="1"
            >
                <div class="material-icon">logout</div>
                {{ 'NAVIGATION.LOGOUT' | translate }}
            </div>

            <div class="footer-links">
                <a (click)="globalService.toggleImprint(); this.close();">{{ 'IMPRINT.TITLE' | translate }}</a>
                <span title="v{{ version }} | {{ngAppStageName}} | {{environment.appId}} | {{this.appRepo.getSelectedCustomer()?.identifier}} | {{ ngAppBranch }} | {{ ngAppBuildDate }}">&nbsp;</span>|
                <span title="{{ this.currentUser?.idpUserId }} [{{this.currentUser?.roles?.join(', ')}}]">&nbsp;</span>
                <a (click)="globalService.togglePrivacy(); this.close();">{{ 'PRIVACY_POLICY.TITLE' | translate }}</a>
            </div>

            <div
                *ngIf="this.ngAppStageName != 'prd' && this.ngAppStageName != 'prod' && this.ngAppStageName != 'demo'"
                class="user-debug"
            >
                <div class="idp">
                    <div class="material-icon">person</div>
                    {{ this.currentUser?.idpUserId }}
                </div>
                <div class="roles">
                    <div class="role" *ngFor="let role of this.currentUser?.roles">{{ role }}</div>
                </div>
            </div>
        </div>
        <div
            *ngIf="selectLanguage$ | async"
            class="language-settings box"
            [@inOutAnim]
        >
            <div class="radio-group">
                <label 
                    *ngFor="let lang of languages"
                    class="tile"
                    tabIndex="1"
                >
                    <input
                        type="radio"
                        [value]="lang"
                        [checked]="lang === translate.currentLang"
                        (change)="setLanguage(lang)"
                    >
                    {{ translations[lang] }}
                </label>
            </div>
        </div>
    </div>
    <div
        [class.page-mask]="isOpen"
        (click)="close()"
    ></div>
    `,
    styleUrls: ['./user-settings.component.scss'],
    animations: [
        trigger('inOutAnim', [
            transition(':enter', [
                useAnimation(inAnimation)
            ]),
            transition(':leave', [
                useAnimation(outAnimation)
            ])
        ])
    ]
})

export class UserSettingsComponent implements OnDestroy, OnInit {
    @Input() set open(open: boolean) {
        if (open) {
            this._setListeners();
        } else {
            this._removeListeners();
            this.selectLanguage$.next(false);
        }
        this.isOpen = open
    };
    @Output() openChange = new EventEmitter<boolean>();
    isOpen: boolean = false;
    readonly ngAppStageName: string = process.env.NG_APP_STAGE_NAME
    readonly ngAppBuildDate: string = process.env.NG_APP_BUILD_DATE
    readonly ngAppBranch: string = process.env.NG_APP_BRANCH
    readonly version: string = packageJson.version
    readonly environment = environment;
    readonly currentUser: User | null;

    availableCustomers$: Observable<(string | undefined)[]> = this._customersConfigService.customersConfig().pipe(
        map((customersConfig) => customersConfig.customers.map((customer) => customer.identifier)),
        share({connector: () => new ReplaySubject(1)})
    );

    selectLanguage$ = new BehaviorSubject<boolean>(false);
    languages: string[] = [];
    translations: { [key: string]: string } = {};

    private _subscriptions: Subscription[] = [];
    private _unlistenEvents: (() => void)[] = [];

    @ViewChild('containerRef', {static: false}) set listener(elRef: ElementRef) {
        if (!elRef) return;
        this._containerRef = elRef;
        this._setListeners()
    }
    private _containerRef: ElementRef | undefined;

    constructor(
        private _customersConfigService: CustomersConfigService,
        private _elRef: ElementRef,
        private _renderer: Renderer2,
        private _router: Router,
        private _userService: UserService,
        public appRepo: appRepository,
        public ngZone: NgZone,
        public router: Router,
        public translate: TranslateService,
        public globalService: GlobalService,
        public appAuthService: AppAuthService,
    ) {
        this.currentUser = this.appRepo.getCurrentUser();
        this.languages = translate.getLangs();
        this.languages.forEach((language) => {
            this.translate.getTranslation(language).pipe(
                take(1),
                filter(translation => translation !== undefined),
                tap(translation => this.translations[language] = translation.COMMON.LANGUAGE_NAME)
            ).subscribe();
        });
    }

    goToPage(path: string) {
        this._router.navigate([path])
        this.close();
    }

    setLanguage(lang: string) {
        // Set language to User in BE
        if (environment.availableLanguagesList.includes(lang)) {
            this.translate.use(lang);
            this._userService.setCurrentUsersLanguage({body: {language: lang as 'de' | 'en'}}).pipe(
                take(1),
                // update returned user in appRepo
                tap((user) => {
                    this.appRepo.updateUser(user)
                    console.log(`%cPreferred language for user set to "${lang}"`, 'color: #4895ef;');
                })
            ).subscribe();
        }
        this.close();
    }

    // set document event listeners
    private _setListeners() {
        this.ngZone.runOutsideAngular(() => {
            this._unlistenEvents.push(
                // listen to scroll and mousedown on document once dropdown is rendered
                this._renderer.listen('document', 'scroll', (event) => {
                    this.updateDropdownPosition()
                })
            )
        })
        this._unlistenEvents.push(
            this._renderer.listen('document', 'mousedown', (event) => {
                let inComponent = this._elRef.nativeElement.contains(event.target),
                    ofTrigger = (event.target as any).classList.contains('user-trigger');
                
                if (!inComponent && !ofTrigger) {
                    this.close()
                }
            }),
            this._renderer.listen('window', 'keydown.esc', () => this.close())
        )
    }

    updateDropdownPosition() {
        if (!this._containerRef) return
        const height = window.scrollY > 50 ? 48 : 58;
        this._renderer.setStyle(this._containerRef.nativeElement, 'top', height + 'px')
    }

    private _removeListeners() {
        this._unlistenEvents.forEach((fn) => fn())
    }

    close() {
        this.isOpen = false
        this._removeListeners()
        this.openChange.emit(false)
    }

    ngOnDestroy(): void {
        this._removeListeners()
        this._subscriptions.forEach(subscription => {
            subscription.unsubscribe()
        })
    }

    ngOnInit() {
        this.updateDropdownPosition()
    }
}
