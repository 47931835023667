/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiResponse } from '../../models/api-response';

export interface AddNotification$Params {
      body?: {

/**
 * Category of the notification
 */
'category'?: 'update' | 'information' | 'warning' | 'error';

/**
 * Title of the notification
 */
'title'?: string;

/**
 * Description of the notification
 */
'description'?: string;

/**
 * Timestamp when the notification starts
 */
'from_date'?: string;

/**
 * Timestamp when notification ends | empty if endless
 */
'to_date'?: string | null;

/**
 * Whether the notification should be persistent
 */
'persistent'?: boolean;

/**
 * List of tenant identifiers
 */
'tenants'?: Array<string>;
}
}

export function addNotification(http: HttpClient, rootUrl: string, params?: AddNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
  const rb = new RequestBuilder(rootUrl, addNotification.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ApiResponse>;
    })
  );
}

addNotification.PATH = '/notifications/';
