import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, debounceTime, filter, takeUntil, tap } from 'rxjs';

export interface SelectSingleOption {
    label: string | number, 
    value: string | number, 
    metaSearch?: (string | number)[] // add hidden values with which this entry can be found by when searching the list
}

@Component({
    selector: 'app-select-single',
    template: `
        <app-dropdown-button
            [title]="title"
            [desc]="buttonDesc"
            [empty]="isEmpty"
            [bodyClass]="bodyClasses.join(' ')"
            [maxBodyHeight]="bodyMaxHeight"
            [size]="size"
            [additionalInfo]="additionalInfo"
            [required]="required"
            [disabled]="!options || options.length === 0 || disabled"
            [open]="(dropdownOpen$ | async) ?? false"
            [chevron]="chevron"
            [keepPosition]="keepPosition"
            (onClose)="dropdownOpen$.next(false)"
            (onEnter)="dropdownOpen$.next(false)"
            (onOpen)="dropdownOpen$.next(true)"
            (onSearch)="searchInput?.nativeElement.focus()"
        >
            <ng-container body>
                <div 
                    *ngIf="options && options.length > 9"
                    class="position-relative"
                >
                    <input 
                        type="text"
                        [placeholder]="'COMMON.SEARCH' | translate"
                        (input)="filterOptions($event)"
                        #searchInput
                    >
                    <button
                        class="delete-search"
                        (click)="resetSearch()"
                    ></button>
                </div>
                <cdk-virtual-scroll-viewport 
                    [itemSize]="35"
                    class="scroll-viewport"
                    [class.has-search]="options && options.length > 9"
                    [minBufferPx]="400"
                    [maxBufferPx]="600"
                    #scrollContainer
                >
                    <div 
                        *cdkVirtualFor="let option of filteredOptions; templateCacheSize: 15"
                        class="list-item"
                    >
                        <button
                            type="button"
                            [disabled]="option.disabled"
                            (click)="selectValue(option)"
                        >
                            {{ option.label }}
                        </button>
                    </div>
                </cdk-virtual-scroll-viewport>
            </ng-container>
        </app-dropdown-button>
    `,
    styleUrls: ['./select-single.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSingleComponent implements OnChanges {
    @ViewChild('scrollContainer') scrollContainer: CdkScrollable | undefined;
    scrollOffsetTop: number = 0;
    @ViewChild('searchInput') searchInput: ElementRef | undefined = undefined;
    // Title of Dropdown
    @Input('title') title!: string;
    // available options
    public options: SelectSingleOption[] | null = null;
    @Input({alias: 'options'}) set _options(options: SelectSingleOption[] | null) {
        this.options = options;
        this.bodyMaxHeight = options && options.length < 9 ? options.length * 35 : undefined;
    }
    @Input('size') size: 'default' | 'small' = 'default';
    // additional information on info icon in button
    @Input() additionalInfo: string | undefined;
    // whether this filter is disabled
    @Input('disabled') disabled: boolean = false;
    // whether this filter is required
    @Input('required') required: boolean | undefined;
    // shows chevron on right side
    @Input() chevron: boolean = false;
    // will keep initial position of dropdown content
    @Input() keepPosition: boolean = false;
    // classes for body of dropdown
    bodyClasses: string[] = ['overflow-hidden'];
    // parent classes of dropdown group
    parentClasses: string[] = ['desc-smaller', 'placeholder'];
    // manually setting max height if only a few options
    bodyMaxHeight: number | undefined;
    // previous value to check against before applying changes
    previousSelection: SelectSingleOption | undefined;
    // input to show current selection
    @Input() activeSelection: string | number | undefined | null;
    // Output current selection
    @Output() activeSelectionChange = new EventEmitter<number | string>();
    // values of disabled options
    @Input() disabledValues: SelectSingleOption['value'][] = [];
    // filtered options
    filteredOptions: any[] | undefined;
    buttonDesc: string = "select";
    isEmpty: boolean = true;
    // keep track of dropdown state
    public dropdownOpen$ = new BehaviorSubject<boolean>(false);

    constructor(
        private _translate: TranslateService
    ) {
        // focus search input and scroll to last position when opening dialog
        this.dropdownOpen$.pipe(
            takeUntilDestroyed(),
            filter((state) => state === true),
            debounceTime(20),
            tap((state) => {
                this.scrollContainer!.scrollTo({ top: this.scrollOffsetTop })
                if (this.searchInput) this.searchInput.nativeElement.focus()
            })
        ).subscribe()

        // update placeholder with new translations
        this._translate.onLangChange.pipe(
            takeUntilDestroyed(),
            tap(() => this._updatePlaceholder())
        ).subscribe()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes['_options'] || changes['disabledValues']) && this.options) {
            this.filteredOptions = this.options;
            this.applyDisabledOptions()
        }
        if (changes['activeSelection']) {
            this._updatePlaceholder()
        }
    }

    filterOptions(search: Event) {
        let query = (search.target as HTMLInputElement).value;
        if (query && query.length > 0) {
            this.filteredOptions = this.options?.filter(option => {
                const lowerCaseQuery = query.toLowerCase();
                return  JSON.stringify(option.value).toLowerCase().includes(lowerCaseQuery) ||
                        JSON.stringify(option.label).toLowerCase().includes(lowerCaseQuery) ||
                        (option.metaSearch && JSON.stringify(option.metaSearch).toLocaleLowerCase().includes(lowerCaseQuery))
            })
            // if matches are found, scroll to top
            if (this.filteredOptions && this.filteredOptions.length > 0) {
                this.scrollContainer!.scrollTo({ top: 0 })
            }
        } else {
            if (this.options !== null) this.filteredOptions = this.options;
            this.scrollContainer!.scrollTo({ top: this.scrollOffsetTop })
        }
    }

    resetSearch() {
        // delete search input, set input back in focus
        this.filteredOptions = this.options || [];
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
            this.searchInput.nativeElement.focus()
        }
    }

    applyDisabledOptions() {
        if (!this.filteredOptions || this.filteredOptions.length == 0 || !this.disabledValues || this.disabledValues.length == 0) return

        const bools = [false, true];
        this.filteredOptions = (this.filteredOptions ?? []).map((option) => {
            return {
                ...option,
                disabled: this.disabledValues.includes(option.value)
            }
        }).sort((oA, oB) => bools.indexOf(oA.disabled ?? true) - bools.indexOf(oB.disabled ?? false));
    }

    // update filters value in repo when dropdown is closed
    selectValue(option: SelectSingleOption) {
        this.dropdownOpen$.next(false);
        this.scrollOffsetTop = this.scrollContainer?.measureScrollOffset('top') || 0;
        // compare changes
        if (!this.previousSelection || option.value !== this.previousSelection.value) {
            this.buttonDesc = option.label.toString();
            // remove placeholder class from body
            const placeholderClassIndex = this.parentClasses.indexOf('placeholder');
            if (placeholderClassIndex > -1) {
                this.parentClasses.splice(placeholderClassIndex, 1)
            }
            this.activeSelectionChange.emit(option.value)
            this.previousSelection = option
        }
    }

    private _updatePlaceholder() {
        const placeholderClassIndex = this.parentClasses.indexOf('placeholder');
        if (this.activeSelection !== null && this.activeSelection !== undefined) {
            this.buttonDesc = this.activeSelection.toString();
            // remove placeholder class from parent
            if (placeholderClassIndex > -1) {
                this.parentClasses.splice(placeholderClassIndex, 1)
            }
            this.isEmpty = false;
        } else {
            // add placeholder class to parent
            if (placeholderClassIndex == -1) {
                this.parentClasses.push('placeholder')
            }
            this.previousSelection = undefined;
            this.isEmpty = true;
            this.buttonDesc = this._translate.instant('COMMON.SELECT')
        }
    }
}
