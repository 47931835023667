import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subject, filter, map, share, takeUntil, tap, withLatestFrom } from 'rxjs';
import { detailsRepository } from '../stores/details.repository';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
// controls global functions in the app, e.g. imprint or privacy modal
export class GlobalService implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    private _renderer: Renderer2 | undefined;
    public imprintOpen = signal(false);
    public privacyOpen = signal(false);
    public dateRangeWarningOpen = signal(false);
    // dont display warning if user selected "dont show again"
    private _dontShowdateRangeWarning: boolean = false;
    // supplies current url
    public currentUrl$: Observable<string> = this._router.events.pipe(
        // we only want to return the url of navigationEnd events
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).urlAfterRedirects),
        share({connector: () => new ReplaySubject(1)})
    )
    // supplies current urlEnd
    public urlEnd$: Observable<string> = this.currentUrl$.pipe(
        map((url) => {
            const urlArr = url.split('/').filter(x => x !== '');
            return urlArr[urlArr.length - 1]
        })
    )
    private _meterValueEstOpen$ = new BehaviorSubject<boolean>(false);
    public meterValueEstOpen$ = this._meterValueEstOpen$.asObservable();

    constructor(
        private _router: Router,
        private _detailsRepo: detailsRepository,
        private _rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private _document: Document
    ) {
        this._renderer = this._rendererFactory.createRenderer(null, null);

        // show "verify date range" if long interval is set
        this.currentUrl$.pipe(
            takeUntil(this._destroying$),
            // filter out routing events on /details views
            filter((url) => {
                const urlArr = url.split('/').filter(x => x !== '');
                return !urlArr.includes('details')
            }),
            withLatestFrom(this._detailsRepo.interval$),
            tap(([url, interval]) => {
                if (interval > 30) this.toggleDateRangeWarning()
            })
        ).subscribe()


        // stores unsubscribe method of renderer listener
        let _fullscreenListener: (() => void) | undefined;
        // listen to changes in fullscreen mode
        this._isFullscreen$.pipe(
            takeUntil(this._destroying$),
            tap((enterFullscreen) => {
                if (enterFullscreen) {
                    // request fullscreen in browser API
                    this._document.documentElement.requestFullscreen({ navigationUI: "show" })
                        .catch((err: any) => {
                            console.error(
                                `An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`,
                            );
                        });
                    
                    // listen to changes once fullscreen is active, set state once user leaves mode
                    _fullscreenListener = this._renderer?.listen('window', 'fullscreenchange', () => {
                        if (!this._document.fullscreenElement) this.toggleFullscreen(false);
                    })
                } else if (_fullscreenListener) {
                    // if no fullscreen is active, we can remove the eventListener on window
                    // by calling the stored unlistener method
                    this._document.exitFullscreen()
                    _fullscreenListener();
                }
            })
        ).subscribe()
    }

    public toggleImprint(forcedState?: boolean) {
        this.imprintOpen.set(forcedState ?? !this.imprintOpen())
    }

    public togglePrivacy(forcedState?: boolean) {
        this.privacyOpen.set(forcedState ?? !this.privacyOpen())
    }

    public toggleDateRangeWarning(forcedState?: boolean) {
        if (this._dontShowdateRangeWarning) forcedState = false;
        this.dateRangeWarningOpen.set(forcedState ?? !this.dateRangeWarningOpen())
    }

    public dontShowRangeWarningAgain() {
        this._dontShowdateRangeWarning = true
    }

    public toggleMeterValueEstimator(forcedState?: boolean) {
        this._meterValueEstOpen$.next(forcedState ?? !this._meterValueEstOpen$.getValue())
    }

    // takes care of fullscreen mode in app
    private _isFullscreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isFullscreen$: Observable<boolean> = this._isFullscreen$.asObservable();

    // callable without any params to toggle when no state is known, state can be forced
    public toggleFullscreen(forcedState?: boolean) {
        const value = forcedState !== undefined ? forcedState : !this._isFullscreen$.getValue();
        this._isFullscreen$.next(value)
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();        
    }
}
