import { CustomerVariables } from "./customer-variables";

export const defaultVariables: CustomerVariables = {
    // primary
    primary: "#440099",
    primaryActive: "#3e0584",
    primary75: "#7330c5bf",
    primary50: "#7330c580",
    primary25: "#d3c7e9",
    primary15: "#7330c526",
    primary10: "#f0eef7",
    primaryDark: "#3c0084",

    // secondary
    secondary: "#440099",
    secondaryActive: "#3e0584",
    secondary75: "#4e02abbf",
    secondary50: "#4e02ab80",
    secondary25: "#4e02ab40",
    secondaryDark: "#3c0084",

    // tertiary
    tertiary: "#B7B0E4",
    tertiaryActive: "#9a93ca",
    tertiary75: "#b7b0e4bf",
    tertiary50: "#b7b0e480",
    tertiary25: "#b7b0e440",
    tertiaryDark: "#8880bf",

    // station states
    stateOk: "#027B75",
    stateToBeMonitored: "#F8BB00",
    statePotentialFailure: "#DD7900",
    stateFailure: "#B92001",
    stateNoData: "#C4C4C4",

    // others
    background: "#F6F6F7",
    background50: "#F2F2F2",
    text: "#39393A",
    text75: "#39393abf",
    text50: "#3a3a3b80",
    text25: "#BFBFBF",
    textActive: "#404040",
    textAccent: "#94A3B8",
    icon: "#8F9192",
    iconActive: "#727475",
    collapsible: "#ffffff",
    collapsed: "#E8E8E8",
    collapsedActive: "#D1D1D1",
    accent: '#C4C4C4',
    accentDark: '#94A3B8',

    // navbar
    navbarPrimary: "#B7B0E4",
    navbarSecondary: "#430095",
    navbarSecondaryActive: "#3e0584",
    navbarSecondaryDark: "#3c0084",
    navbarTertiary: "#221C35",

    // timeline
    timelineSessionLabels: "#430095",
    timelineSessionBlocks: "#B7B0E4",
    timelineText: "#475569",
    timelineSelection: "#8663FD",

    // kpi
    kpiPrimary: "#440099",
    kpiSecondary: "#B7B0E4",
    kpiText: "#495567",
    kpiText15: "#49556726",
    kpiIcon: "#B7B0E4"
};
