import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StateHelperService } from 'src/app/core/helpers/state-helper.service';

@Component({
    selector: 'app-preloader',
    template: `
        <!-- Circular Preloader -->
        <svg 
            *ngIf="type == 'circle'" 
            class="spinner"
            [ngClass]="size == 'small' ? 'small' : ''"
        >
            <circle 
                class="track"
                [attr.cx]="size == 'default' ? 32 : 20"
                [attr.cy]="size == 'default' ? 32 : 20"
                [attr.r]="size == 'default' ? 24 : 12"
            ></circle>
            <circle 
                class="path" 
                [attr.stroke]="color"
                [attr.cx]="size == 'default' ? 32 : 20"
                [attr.cy]="size == 'default' ? 32 : 20"
                [attr.r]="size == 'default' ? 24 : 12"
            ></circle>
        </svg>
        <!-- Squares Preloader -->
        <div *ngIf="type == 'squares'" class="squares">
            <div 
                *ngFor="let i of [1, 2, 3, 4]" 
                [ngClass]="'bounce'+i"
                [style]="'background-color:'+color"
            ></div>
        </div>
    `,
    host: {
        '[class.position-relative]': 'true',
        '[class.d-i-block]': 'true'
    },
    styleUrls: ['./preloader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreloaderComponent {

    private _stateHelper = new StateHelperService();

    @Input() type: 'circle' | 'squares' = 'circle';
    @Input() size: 'default' | 'small' = 'default';
    @Input() color: string = this._stateHelper.getVar('--primary');

    constructor() {}

}
