import { Recommendation } from "src/app/overview/overview.component";
import { ChargingStation, Connector } from "../data-backend/models";
import { EnumeratedState } from "./state-helper.service";
import { PermissionsService } from "../app-services/permissions.service";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export interface ExtendedChargingStation extends ChargingStation {
    recommendation?: Recommendation | null; 
    recommendationComment?: string | null;
    featuredConnector: Connector;
}

/**
 * This helper is used to provide different functions to transform the given ChargingStation object.
 * Feel free to add more functions to this helper, which can be used repeatedly in the application.
 */
@Injectable({
    providedIn: 'root'
})
export class ChargingStationTransformer {
    private _stateRanking: EnumeratedState[] = ['No Data', 'Ok', 'To Be Monitored', 'Potential Failure', 'Failure'];
    private _healthIndexRanking: {min: number, max: number, state: EnumeratedState}[] = [
        {
            min: 75,
            max: 100,
            state: 'Ok'
        },
        {
            min: 50,
            max: 75,
            state: 'To Be Monitored'
        },
        {
            min: 0,
            max: 50,
            state: 'Failure'
        }
    ];

    constructor(
        private _permService: PermissionsService,
        private _translate: TranslateService
    ) { }

    /**
     * returns a value corresponding rank based on a predefined ranking array. HealthIndex will be transformed
     * to an EnumeratedState based on the _healthIndexRating
     * @param {EnumeratedState | number} state - The `state` parameter in the `_rank` function can be
     * either of type `EnumeratedState` or `number`. If it is a number, it represents the health index
     * value that needs to be transformed into an `EnumeratedState` based on certain criteria.
     * @returns The `_rank` function returns a number representing the rank of the input state in the
     * `_stateRanking` array. If the input state is a number, it is transformed into an EnumeratedState
     * based on the `min` and `max` values in the `_healthIndexRanking` array.
     */
    private _rank(state: EnumeratedState | number): number {
        // transform healthIndex to EnumeratedState
        let enumState = typeof state === 'number' 
            ? this._healthIndexRanking.find(({min, max}) => state >= min && state <= max)?.state ?? 'No Data'
            : state;

        let res = this._stateRanking.indexOf(enumState);
        // lowest rank is 0, undefined counts as "No Data"
        res = res > -1 ? res : 0;

        return res
    }

    /**
     * takes a 2D array, flattens it, and returns a new array with unique, non-null and non-undefined elements.
     * @param {any[][]} array - The `array` parameter in the `_joinUnique` function is a
     * two-dimensional array of any type. Each element in the outer array represents a group of
     * elements, and each inner array contains the elements within that group.
     * @returns An array with unique values from the input array, after flattening it and filtering out
     * any null or undefined values.
     */
    private _joinUnique(array: any[][]): any[] {
        return array
                .flat()
                .filter((item, index, self) => item !== null && item !== undefined && self.indexOf(item) === index)
    }

    public extendedChargingStationTransformer(station: ChargingStation): ExtendedChargingStation {
        // set first connector as default
        let featuredConnector = { ...station.connectors[0] };
        
        if (station.connectors.length > 1) {
            // get all available and featured states from user permissions and merge when multiple permission sets are given
            //                                                                                   v  sadly this is needed 🤢🤮
            const featuredStates    = this._joinUnique((this._permService.getPermissions('global.featuredStates') as any[][])) as ('lastOverallState' | 'lastChargingModelState' | 'lastErrorState')[];
            const availableStates   = this._joinUnique((this._permService.getPermissions('global.availableStates') as any[][])) as ('lastOverallState' | 'lastChargingModelState' | 'lastErrorState' | 'lastHealthIndexValue')[];

            // find lowest rated connector
            const conStateRanks = station.connectors.reduce((worstCon, connector) => {
                const featuredStatesRank    = featuredStates.reduce((acc, state) => acc + this._rank(connector[state]), 0);
                const availableStatesRank   = availableStates.reduce((acc, state) => acc + this._rank(connector[state]), 0);
                const rank = featuredStatesRank + availableStatesRank;

                return rank > worstCon.rank ? {con: connector, rank} : worstCon;
            }, {con: {}, rank: -1});

            station.connectors = station.connectors.sort((a, b) => a.connectorId - b.connectorId)
            featuredConnector = { ...conStateRanks.con } as Connector
        }

        // check if all provided data is null
        const allAverageUsageEnergyNull = station.connectors.every((con) => con.averageUsageEnergy === null || con.averageUsageEnergy === undefined);
        const allAverageUsageRegularSessionsNull = station.connectors.every((con) => con.averageUsageRegularSessions === null || con.averageUsageRegularSessions === undefined);

        // add average usage data for display in OV table if available
        // if not available, write value of -1 (the models provided through the openAPI specs expect these values to be defined). We'll filter out -1 values in the renderFn
        featuredConnector.averageUsageEnergy = allAverageUsageEnergyNull ? -1 : station.connectors.reduce((sum, conn) => sum + (conn.averageUsageEnergy || 0), 0);
        featuredConnector.averageUsageRegularSessions = allAverageUsageRegularSessionsNull ? -1 : station.connectors.reduce((sum, conn) => sum + (conn.averageUsageRegularSessions || 0), 0);

        // patch empty location IDs
        if (station['locationId'] == null) station['locationId'] = this._translate.instant('COMMON.NOT_AVAILABLE')

        return {
            ...station,
            featuredConnector
        };
    }
}
