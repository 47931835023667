import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, ElementRef, EventEmitter, input, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-collapsible',
    template: `
    <div [class.container]="!fullWidth">
        <div class="collapsible {{ parentClasses.join(' ') }}" [attr.collapsed]="collapsed">
            <div [class.full-width]="fullWidth">
                <button 
                    class="collapsible-header" 
                    [attr.disabled]="active() ? null : 'disabled'"
                    [class.loading]="!active() && this.loading"
                    (click)="onHeaderClick($event)"
                    (keydown.space)="onSpaceEnter($event)"
                    (keydown.enter)="onSpaceEnter($event)"
                    tabindex="1"
                    #buttonRef
                >
                    <ng-content select="[header]"></ng-content>
                    @if (showChevron) {
                        <span class="chevron">
                            <span class="material-icon">
                                expand_more
                            </span>
                        </span>
                    }
                </button>
                <div 
                    class="collapsible-body"
                    [@collapse]="collapsed"
                    (@collapse.done)="emitEvents()"
                >
                    <div class="collapsible-body-wrapper {{ bodyClasses.join(' ') }}">
                        <ng-content select="[body]"></ng-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
  `,
    styleUrls: ['./collapsible.component.scss'],
    animations: [
        trigger('collapse', [
            state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
            state('true', style({ height: '0', visibility: 'hidden' })),
            transition('false => true', animate('250ms ease-in')),
            transition('true => false', animate('250ms ease-out'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsibleComponent {
    @ViewChild('buttonRef') buttonRef: ElementRef | undefined;
    @Input() collapsed: boolean | null = true;
    @Input() bodyClasses: string[] = [];
    @Input() parentClasses: string[] = [];
    @Input() fullWidth: boolean = false;
    // optionally set active to false to disable expanding the collapsible (e.g. content still polling)
    public active = input<boolean>(true);
    // optionally hide the chevron
    @Input() showChevron: boolean = true;
    // set loading to true for skeleton-like animation
    @Input() loading: boolean = false;
    // optionally pass class of elements in header that should trigger opening of collapsible
    // if set, only elements with that class or collapsible's chevron class can trigger opening
    @Input() triggerClass: string | undefined;
    @Output() onOpen = new EventEmitter<boolean>();
    @Output() onClose = new EventEmitter<boolean>();

    constructor(
        private _cdr: ChangeDetectorRef
    ) {
        // collapse component if active is being set to false
        effect(() => {
            if (this.active() == false) {
                this.collapsed = true;
                // TODO: we currently need to manually mark this component for the next CD cycle, as we're using OnPush
                // and don't catch these changes made here. We need to refactor this component to use signal inputs
                // and reduce the onOpen / onClose eventEmitters to a single "collapsed" model signal.
                this._cdr.markForCheck();
            }
        })
    }

    onHeaderClick(event: Event) {
        // toggle if no certain trigger is set, or clicked on certain trigger
        if (
            !this.triggerClass ||
            (event.target instanceof Element && (event.target.classList.contains(this.triggerClass) || event.target.classList.contains('chevron')))
        ) {
            this.collapsed = this.active() ? !this.collapsed : true;
        }
    }

    // handle space / enter events if custom trigger is defined
    onSpaceEnter(event: Event) {
        // toggle if space was applied while button in focus
        if (this.triggerClass && event.target instanceof Element && event.target === this.buttonRef?.nativeElement) {
            this.collapsed = this.active() ? !this.collapsed : true;
        }
    }

    emitEvents() {
        if (this.collapsed === false) {
            this.onOpen.emit(true)
        } else {
            this.onClose.emit(true)
        }
    }
}
