import { Injectable } from '@angular/core';
import { ChargingStation, Connector } from '../data-backend/models';
import { defaultVariables } from '../customer-variables/default-customer-variables';

export type EnumeratedState = ('Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data');

export interface StateColors {
    ok: string,
    toBeMonitored: string,
    potentialFailure: string,
    failure: string,
    noData: string
}

@Injectable({
    providedIn: 'root'
})
export class StateHelperService {
    // TODO: currently we only have a single set of customer variables, thus we're importing them manually
    // This is only to be seen as a hotfix, since the dynamic solution caused some issues
    private _defaultColors = defaultVariables;
    private _colorOk = this._defaultColors.stateOk ?? this.getVar('--state-ok');
    private _colorToBeMonitored = this._defaultColors.stateToBeMonitored ?? this.getVar('--state-to-be-monitored');
    private _colorPotentialFailure = this._defaultColors.statePotentialFailure ?? this.getVar('--state-potential-failure');
    private _colorFailure = this._defaultColors.stateFailure ?? this.getVar('--state-failure');
    private _colorNoData = this._defaultColors.stateNoData ?? this.getVar('--state-no-data');
    enumeratedStates: EnumeratedState[] = ['Ok', 'To Be Monitored', 'Potential Failure', 'Failure'];
    enumeratedStateColors: string[] = [this._colorOk, this._colorToBeMonitored, this._colorPotentialFailure, this._colorFailure];

    constructor() { }

    // returns lowest state of enumerated states in connector (ok, to be monitored, etc...) + corresponding color
    getLowestEnumeratedStateOfStation(station: ChargingStation, key: keyof Connector): [EnumeratedState, string] {
        // map each stations lowest enumerated state
        let lowestEnumeratedStates: EnumeratedState[] = station.connectors.map(connector => {
            return connector[key] as EnumeratedState
        }).filter(x => x !== undefined);

        return this.getLowestEnumeratedStateInArray(lowestEnumeratedStates)
    }

    // returns lowest state in array of enum states + corresponding color
    getLowestEnumeratedStateInArray(inArray: EnumeratedState[]): [EnumeratedState, string] {
        // get value by comparing to preset scale
        let evalIndex = Math.max(...inArray.map(val => this.enumeratedStates.indexOf(val as EnumeratedState))),
            lowestState = evalIndex > -1 ? this.enumeratedStates[evalIndex] : 'No Data',
            lowestStateColor = evalIndex > -1 ? this.enumeratedStateColors[evalIndex] : '#8F9192';

        return [lowestState, lowestStateColor]
    }

    // returns connector obj of lowest provided state
    getConnectorOfLowestState(station: ChargingStation, key: keyof Connector): Connector {
        // map each stations lowest enumerated state
        let lowestEnumeratedStates: EnumeratedState[] = station.connectors.map(connector => {
            return connector[key] as EnumeratedState
        }).filter(x => x !== undefined);

        const [state, color] = this.getLowestEnumeratedStateInArray(lowestEnumeratedStates);
        const index = lowestEnumeratedStates.indexOf(state);

        return station.connectors[index]
    }

    getLowestHealthIndex(station: ChargingStation): number {
        const allHIs = station.connectors.map((x) => x.lastHealthIndexValue);
        return Math.max(...allHIs);
    }

    // returns connector obj of latest provided date key
    getConnectorOfLastDate(station: ChargingStation, key: keyof Connector): Connector {
        return station.connectors.sort((conA, conB) => {
            return new Date(conA[key] as string).getTime() - new Date(conB[key] as string).getTime()
        })[0];
    }

    // sorts by enumerated state, -1 = worst -> best, 1 = best -> worst
    public sortByState(a: EnumeratedState, b: EnumeratedState, direction: -1 | 1 = -1) {
        let aIndex = this.enumeratedStates.indexOf(a),
            bIndex = this.enumeratedStates.indexOf(b),
            res = aIndex > bIndex ? 1 : aIndex < bIndex ? - 1 : 0;

        return res * direction
    }

    // get a statecolors object with all state hex values
    getStateColors() {
        const stateColors: StateColors = {
            ok: this._colorOk,
            toBeMonitored: this._colorToBeMonitored,
            potentialFailure: this._colorPotentialFailure,
            failure: this._colorFailure,
            noData: this._colorNoData
        };
        return stateColors;
    }

    // set lowest state of available models als lastOverallState
    patchOverallState(station: ChargingStation, featuredStates: string[]): ChargingStation {
        station.connectors = station.connectors.map((con) => {
            const featuredStateValues = featuredStates.map((state) => {
                let value: any = con[state as keyof Connector];
                if (!value) value = station[state as keyof ChargingStation];
                return value
            });
            const [lowestFeaturedState] = this.getLowestEnumeratedStateInArray(featuredStateValues);
            con.lastOverallState = lowestFeaturedState ?? 'No Data';

            return con
        })
        return station
    }

    // takes 0-1 as argument
    percentToHex(p: number) {
        const intValue = Math.round(p * 255); // map percent to nearest integer (0 - 255)
        const hexValue = intValue.toString(16); // get hexadecimal representation
        return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
    }

    // get a css var value
    getVar(cssVar: string) {
        return getComputedStyle(document.documentElement).getPropertyValue(cssVar).trim();
    }

}
