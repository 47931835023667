{
  "name": "eon-csm",
  "version": "0.0.258",
  "scripts": {
    "ng": "ng",
    "start": "set -a && . local.env && ng serve",
    "generate-api": "npx ng-openapi-gen -c ng-openapi-gen.data.json && npx ng-openapi-gen -c ng-openapi-gen.auth.json",
    "generate-api_v2": "npx ng-openapi-gen -c ng-openapi-gen.data_v2.json && npx ng-openapi-gen -c ng-openapi-gen.auth_v2.json",
    "generate-api_v3": "npx ng-openapi-gen -c ng-openapi-gen.data_v3.json && npx ng-openapi-gen -c ng-openapi-gen.auth_v3.json",
    "build": "ng build",
    "build-docker": "ng build --output-hashing all",
    "build-docker-dev": "ng build --configuration development --output-hashing all",
    "watch": "ng build --watch --configuration development",
    "test": "set -a && source tests.env && ng test",
    "test_ci": "ng test --karma-config karma.ci.conf.js",
    "clean": "ng cache clean",
    "storybook": "ng run eon-csm:storybook",
    "build-storybook": "ng run eon-csm:build-storybook"
  },
  "engines": {
    "npm": ">= 8.5.5",
    "node": ">= 20.9.0"
  },
  "browserslist": [
    "defaults"
  ],
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.1",
    "@angular/cdk": "^17.1.2",
    "@angular/cli": "^17.3.1",
    "@angular/common": "^17.3.1",
    "@angular/compiler": "^17.3.1",
    "@angular/core": "^17.3.1",
    "@angular/forms": "^17.3.1",
    "@angular/platform-browser": "^17.3.1",
    "@angular/platform-browser-dynamic": "^17.3.1",
    "@angular/router": "^17.3.1",
    "@angular/service-worker": "^17.3.1",
    "@ngneat/elf": "^2.5.1",
    "@ngneat/elf-entities": "^5.0.0",
    "@ngneat/elf-pagination": "^1.1.0",
    "@ngneat/elf-persist-state": "^1.2.0",
    "@ngneat/elf-requests": "^1.9.2",
    "@ngneat/elf-state-history": "^1.4.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/echarts": "^4.9.22",
    "@types/mapbox-gl": "^2.7.20",
    "angular-csv-ext": "^1.0.5",
    "angular-oauth2-oidc": "file:./local_packages/angular-oauth2-oidc-17.0.1.tgz",
    "angular-plotly.js": "^5.2.2",
    "browser-fs-access": "^0.35.0",
    "date-fns": "^2.29.3",
    "dotenv": "^16.4.1",
    "echarts": "^5.4.3",
    "jszip": "^3.10.1",
    "mapbox-gl": "^3.1.2",
    "ng-zorro-antd": "^17.2.0",
    "ngx-toastr": "^18.0.0",
    "npm": "^10.4.0",
    "plotly.js-strict-dist-min": "^2.31.1",
    "rxjs": "~7.8.1",
    "rxjs-interop": "^2.0.0",
    "swiper": "^11.0.6",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.1",
    "@angular/compiler-cli": "^17.3.1",
    "@compodoc/compodoc": "^1.1.23",
    "@ngneat/elf-cli-ng": "^1.0.0",
    "@ngneat/elf-devtools": "^1.3.0",
    "@ngx-env/builder": "17.1.2",
    "@storybook/addon-docs": "^7.6.12",
    "@storybook/addon-essentials": "^7.6.12",
    "@storybook/addon-interactions": "^7.6.12",
    "@storybook/addon-links": "^7.6.12",
    "@storybook/addon-styling-webpack": "^0.0.6",
    "@storybook/angular": "^7.6.12",
    "@storybook/blocks": "^7.6.12",
    "@storybook/test": "^7.6.12",
    "@types/jasmine": "~5.1.4",
    "@types/node": "20.11.16",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-openapi-gen": "^0.51.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "scuri": "^1.4.0",
    "storybook": "^7.6.12",
    "typescript": "~5.3",
    "bun": "^1.1.7"
  }
}